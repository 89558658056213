import React, { useEffect, useState } from 'react';
import './expense-admin.styles.scss';
import { Form, Button, Spinner, Table, Modal } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';
import firebase from 'firebase/app';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ExpenseAdmin = () => {
    // Date Values
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    // Modal controls and states
    const [selectedExpenseId, setSelectedExpenseId] = useState("");
    const [showExpenseId, setShowExpenseId] = useState(false);
    const [settlementNote, setSettlementNote] = useState("");

    const handleClose = () => setShowExpenseId(false);
    const handleShow = () => setShowExpenseId(true);

    // Handle Save Settlement
    const handleSaveSettlement = async () => {
        const docRef = await firestore.collection("purchase-expense-logs").where('expenseId', '==', `${selectedExpenseId}`).limit(1).get()
            .then(async (query) => {
                const log = await query.docs[0];
                await log.ref.update({
                    settlementNote: `${settlementNote}`,
                    status: "Settled",
                    settledOn: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(async () => await setSettlementNote("")).then(async () => await window.location.reload(false));
            })
    }

    // Admin Login
    const [expAdminLoggedIn, setExpAdminLoggedIn] = useState(Boolean(localStorage.getItem('expAdminLoggedIn')));
    const [password1, setPassword1] = useState('');

    // Employee Array
    const [employeeList, setEmployeeList] = useState([]);

    // Handle Local for logged in
    useEffect(() => {
        const onLoadAdminChanges = async () => {
            await setExpAdminLoggedIn(Boolean(localStorage.getItem('expAdminLoggedIn')));
        };
        onLoadAdminChanges();
    }, [])

    // HANDLE LOGIN
    const handleLogin = async () => {
        const docRefUser = await firestore.collection("stores").doc("warehouse-catkodagu-profile");
        await docRefUser.get()
            .then((doc) => {
                if (password1 === doc.data().expenseAdminPW) {
                    localStorage.setItem("expAdminLoggedIn", true);
                    return setExpAdminLoggedIn(true);
                } else {
                    alert("Wrong Credentials")
                }
            })
    }

    // Handle log data
    const [expenseLogs, setExpenseLogs] = useState([]);

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("purchase-expense-logs").orderBy('timeAdded', "desc")
                .where('timeAdded', '>=', start).where('timeAdded', '<=', end);
            docRef.get().then(async (snapshot) => {
                let expenseLogsArray = [];
                await snapshot.docs.map(doc => expenseLogsArray.push(doc.data()))
                await setExpenseLogs(expenseLogsArray)
                // Create unique list
                let employeeNameList = [];
                await expenseLogsArray.map((item, i) => employeeNameList.push(item.name.toLowerCase()))
                await setEmployeeList([...new Set(employeeNameList)]);
            })
        };
        fetchLogData();
    }, [value1, value2])

    // Handle Logout
    const handleExpenseLogout = () => {
        localStorage.removeItem("expAdminLoggedIn");
        return setExpAdminLoggedIn(false);
    }

    // Filter Logs
    const [empName, setEmpName] = useState("all");

    const filteredLogs = expenseLogs.filter(log => {
        if (empName === "all") {
            return log.name.toLowerCase().includes("");
        } else {
            return log.name.toLowerCase().includes(empName.toLowerCase());
        }
    })

    return (
        <>
            {expAdminLoggedIn === false ?
                <div className='ckw-expense-login-main-container'>
                    <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width='200px' alt='ckw-expense-logo' />
                    <Form className='admin-login-form'>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Enter Admin Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" onChange={(event) => setPassword1(event.target.value)} />
                        </Form.Group>
                        <Button variant={'dark'} onClick={() => handleLogin()}>Login</Button><br />
                    </Form>
                    <a href="/expense" style={{ margin: "10px" }}><Button variant={'warning'}>&#9194; Go Back</Button></a>
                </div>
                :
                <div className="expense-admin-main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                        <h2 style={{ paddingBottom: "20px", borderBottom: "1px solid gray" }}>EXPENSE ADMIN REPORT</h2>
                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                            <a href="/collections/admin">
                                <Button size="sm" variant="success" style={{ fontSize: "0.8rem", height: "40px" }} >Go To Collection Admin</Button>
                            </a>
                            <Button size="sm" variant="danger" onClick={() => handleExpenseLogout()} style={{ fontSize: "0.8rem", height: "40px" }}>Logout</Button>
                        </div>
                    </div>
                    <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
                    <div style={{ display: "flex", paddingTop: "10px" }} className='expense-report-date-range-selectors'>
                        <div style={{ marginRight: "20px", marginBottom: "20px" }}>
                            <span><b>Select From Date : </b></span>
                            <DatePicker className='expense-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <span><b>Select To Date : </b></span>
                            <DatePicker className='expense-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                        </div>
                    </div>
                    <div className="total-expenses">
                        <h5>Total Expense Amount <span style={{ color: "red" }}>(For the selected date range.)</span></h5>
                        <h4>{filteredLogs.reduce((accumalatedQuantity, log) => accumalatedQuantity + Number(log.amount), 0).toFixed(2)}</h4>
                    </div>
                    <div className="expense-tracker-report" style={{ borderTop: "1px solid black", paddingTop: "10px", fontSize: "0.8rem" }}>
                        <div className="table-top-conatiner">
                            <div>
                                <Form.Control style={{ marginRight: "20px", width: "400px", fontSize: "0.8rem", fontWeight: "bold" }} defaultValue="all" as="select" onChange={(e) => setEmpName(e.target.value)} >
                                    <option style={{ fontWeight: "bold" }}>all</option>
                                    {employeeList.map((name, i) =>
                                        <option key={i + 1} style={{ fontWeight: "bold", textTransform: "capitalize" }}>{name.toLowerCase()}</option>
                                    )}
                                </Form.Control>
                            </div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="expense-table-to-xls"
                                filename="expense-report"
                                sheet="tablexls"
                                buttonText="Export as XLS" />
                        </div>
                        <Table striped bordered hover id="expense-table-to-xls" size="sm" responsive="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Expense ID</th>
                                    <th>Entry Date</th>
                                    <th>Entry Time</th>
                                    <th>Name</th>
                                    <th>Expense Reason</th>
                                    <th>Expense Type</th>
                                    <th>Expense Ref/Note</th>
                                    <th>Expense Amount</th>
                                    <th>Status</th>
                                    <th>Settlement Note</th>
                                    <th>Settlement Date / Time</th>
                                    <th>Process Request</th>
                                </tr>
                            </thead>
                            {filteredLogs.length > 0 ?
                                <tbody>
                                    {filteredLogs.map((item, i) =>
                                        <tr key={i + 1} style={item.status !== "Settled" ? { backgroundColor: "#ffd4d4" } : { backgroundColor: "#dbffdf" }}>
                                            <td>{i + 1}</td>
                                            <td>{item.expenseId ? item.expenseId : "No Data"}</td>
                                            <td>{`${item.timeAdded.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                            <td>{`${item.timeAdded.toDate().toLocaleTimeString()}`}</td>
                                            <td>{item.name ? item.name : "No Data"}</td>
                                            <td>{item.reason ? item.reason : "No Data"}</td>
                                            <td>{item.expenseType ? item.expenseType : "No Data"}</td>
                                            <td>{item.note ? item.note : "No Data"}</td>
                                            <td>{item.amount.toFixed(2)} INR</td>
                                            <td>{item.status ? item.status : "No Data"}</td>
                                            <td>{item.settlementNote ? item.settlementNote : "To Be Settled"}</td>
                                            <td>{
                                                item.settledOn ?
                                                    `Date: ${item.settledOn.toDate().toDateString().split(' ').slice(1).join(' ')}  | Time: ${item.settledOn.toDate().toLocaleTimeString()}`
                                                    :
                                                    "To Be Settled"}
                                            </td>
                                            <td>{item.status !== "Settled" ?
                                                <Button variant="success" style={{ fontSize: "0.6rem" }} onClick={async () => { await setSelectedExpenseId(item.expenseId); await handleShow() }}>Settle Expense</Button>
                                                :
                                                <Button variant="secondary" style={{ fontSize: "0.6rem" }} disabled>Settle Expense</Button>
                                            }</td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan="13"><Spinner style={{ marginTop: "10px" }} animation="border" /></td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                    </div>
                    {/* Settlement Modal */}
                    <Modal show={showExpenseId} centered onHide={handleClose}>
                        <Modal.Header closeButton>
                            <h4>Settlement Details</h4><br />

                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="name">
                                <Form.Label><b>Settlement Note</b></Form.Label>
                                <Form.Control type="text" placeholder="Settlement Note" onChange={(e) => setSettlementNote(e.target.value)} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            {settlementNote.length > 0 ?
                                <Button variant="success" onClick={() => handleSaveSettlement()}>Settle Expense</Button>
                                :
                                <Button variant="success" disabled>Settle Expense</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                </div>}
        </>
    )
}

export default ExpenseAdmin;
