import React, { useState, useEffect } from 'react';
import './wastage-report.styles.scss';
import { Form, Spinner, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from '../../../firebase/firebase.utils';
import firebase from 'firebase/app';

const WastageReport = () => {
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    // State to store the warehouse logs
    const [warehouseLogs, setWarehouseLogs] = useState([]);
    // State to save store name
    const [storeName, setStoreName] = useState("all");

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("warehouse-logs-wastage-bng").orderBy('timeUpdated', "desc")
                .where('timeUpdated', '>=', start).where('timeUpdated', '<=', end);
            docRef.get().then(async (snapshot) => {
                let warehouseLogsArray = [];
                await snapshot.docs.map(doc => warehouseLogsArray.push(doc.data()))
                await setWarehouseLogs(warehouseLogsArray)
            })
        };
        fetchLogData();
    }, [value1, value2])


    return (
        <div className='wastage-report-main-container'>
            <h3>Wastage / Spillage Report</h3>
            <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
            <div className='wastage-report-date-range-selectors'>
                <div>
                    <span><b>Select From Date : </b></span>
                    <DatePicker className='wastage-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div>
                    <span><b>Select To Date : </b></span>
                    <DatePicker className='wastage-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
            </div>
            <div className="wastage-report-table-view">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="wastage-report-to-xls"
                    filename="wastage-report"
                    sheet="tablexls"
                    buttonText="Export to XLS" />
                <Table striped bordered hover size="sm" id="wastage-report-to-xls" style={{ fontSize: "0.9rem" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Wastage ID</th>
                            <th>Time Of Entry</th>
                            <th>Reason</th>
                            <th>Added By</th>
                            <th>Spoilt/Spilled Products</th>
                        </tr>
                    </thead>
                    {warehouseLogs.length > 0 ?
                        <>
                            {warehouseLogs.length !== 0 ?
                                <tbody>
                                    {warehouseLogs.map((item, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.wastageId}</td>
                                            <td>{`${item.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                            <td>{item.wastageReason.toString().replace('{', '').replace('}', '')}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.addedBy}</td>
                                            <td>
                                                <Table striped bordered hover size="sm" style={{ fontSize: "0.8rem" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Product Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.productsUpdated.map((pro, i) =>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{pro.itemId}</td>
                                                                <td>{pro.name}</td>
                                                                <td>{pro.selectedQuantity} {pro.itemMeasure}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="6">No Logs Found</td>
                            }
                        </>
                        :
                        <td colSpan="6"><Spinner variant="info" size="sm" animation="border" /></td>
                    }
                </Table>
            </div>
        </div>
    )
}

export default WastageReport;