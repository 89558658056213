import React, { useEffect, useState } from 'react';
import './main-login.styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';


const MainLogin = ({ setIsLoggedIn }) => {
    const [emailId, setEmailId] = useState('');
    const [password1, setPassword1] = useState('');

    // HANDLE LOGIN
    const handleLogin = async () => {
        const docRefUser = await firestore.collection("stores").doc("warehouse-catkodagu-profile-bng");
        await docRefUser.get()
            .then((doc) => {
                if (emailId === doc.data().userName && password1 === doc.data().password) {
                    localStorage.setItem("warehouseLoggedInBng", true);
                    return setIsLoggedIn(true);
                } else {
                    alert("Wrong Credentials")
                }
            })
    }

    return (
        <div className='ckw-admin-login-main-container'>
            <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width='200px' alt='ckw-admin-logo' />
            <Form className='admin-login-form'>
                <h1>Warehouse Bangalore</h1>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Admin Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Admin Username" onChange={(event) => setEmailId(event.target.value)} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Admin Password</Form.Label>
                    <Form.Control type="password" placeholder="Admin Password" onChange={(event) => setPassword1(event.target.value)} />
                </Form.Group>
                <Button variant={'primary'} onClick={() => handleLogin()}>Login</Button><br />
            </Form>
            {/* <a href='/expense' style={{ marginTop: "30px", fontSize: "0.7rem" }}><Button variant="dark" style={{ fontSize: "0.7rem", width: "250px" }}>Go To Expense Tracker Portal</Button></a>
            <a href='/expense/admin' style={{ marginTop: "30px", fontSize: "0.7rem" }}><Button variant="info" style={{ fontSize: "0.7rem", width: "250px" }}>Expense Admin</Button></a>
            <a href='/collections/admin' style={{ marginTop: "30px", fontSize: "0.7rem" }}><Button variant="warning" style={{ fontSize: "0.7rem", width: "250px" }}>Collections Admin</Button></a> */}
        </div>
    )
}

export default MainLogin;