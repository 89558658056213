import React, { useState, useEffect } from 'react';
import './transfer-out.styles.scss';
import { Button, Modal, Form, Spinner, Table, FormControl } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';
import firebase from 'firebase/app';
import AddProductModal from '../manage-products/manage-product-modals/add-product-modal.component';
import BarcodeReader from 'react-barcode-reader';
import { catStoreList } from '../../../res/catmart-list';

const TransferOut = ({ storeDetails, warehouseProducts, categoryMeta, stockHandlers, updateWarehouseData, updateHandlerData, updateCategoryData, updateStoreDetails }) => {
    const [transferOutId, setTransferOutId] = useState('');
    // Add New Store Details State
    const [storeNumber, setStoreNumber] = useState('');
    const [storeName, setStoreName] = useState('');
    const [storeContact, setStoreContact] = useState('');
    const [storePhone, setStorePhone] = useState('');
    const [storeGstin, setStoreGstin] = useState('');
    const [storeAddress, setStoreAddress] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const [addStoreShow, setAddStoreShow] = useState(false);
    const [selectProductsShow, setSelectProductsShow] = useState(false);
    const [selectedStore, setSelectedStore] = useState("");
    const [handlerName, setHandlerName] = useState("");
    const [addHandlerName, setAddHandlerName] = useState("");
    const [canRefresh, setCanRefresh] = useState(false);
    // Loading Status States
    const [loadingSaveStore, setLoadingSaveStore] = useState(true);
    // Search Product State
    const [productSearch, setProductSearch] = useState("");
    // Add New Product Modal
    const [addProductModalShow, setAddProductModalShow] = useState(false);
    // Selected Products From Modal
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [itemQuantity, setItemQuantity] = useState(null);
    const [itemPurchasePrice, setItemPurchasePrice] = useState(null);
    const [transferCost, setTransferCost] = useState(null);
    const [itemMrp, setItemMrp] = useState(null);
    const [itemMsp, setItemMsp] = useState(null);
    // Scan func and states
    const [scanItem, setScanItem] = useState(false);
    const [scanData, setScanData] = useState(null);

    const handleAddStoreClose = () => setAddStoreShow(false);
    const handleAddStoreShow = () => setAddStoreShow(true);

    const handleSelectProductsClose = () => { setScanItem(false); setScanData(null); setSelectProductsShow(false) };
    const handleSelectProductsShow = () => setSelectProductsShow(true);
    // States and functions to add new handler
    const [showHandlerModal, setShowHandlerModal] = useState(false);

    const handleCloseHandlerModal = () => setShowHandlerModal(false);
    const handleShowHandlerModal = () => setShowHandlerModal(true);

    // Get Store Number
    useEffect(() => {
        const fetchStoreNumber = async () => {
            let storeNum = [];
            const storeNumberRef = await firestore.collection("storeCounter").doc("store-counter");
            await storeNumberRef.get().then(async (doc) => await storeNum.push(doc.data().storeId));
            await setStoreNumber(Number(storeNum[0]))
        };
        fetchStoreNumber();
    }, [setStoreNumber])

    // Filter Search Products
    const filteredProducts = warehouseProducts.filter(item => {
        if (scanItem === false && scanData === null) {
            return item.name.toLowerCase().includes(productSearch.toLowerCase()) || item.itemId.toLowerCase().includes(productSearch.toLowerCase());
        } else if (productSearch.length > 0) {
            return item.name.toLowerCase().includes(productSearch.toLowerCase());
        } else {
            return item.itemId.toLowerCase().includes(scanData === null ? "" : scanData.toString().toLowerCase());
        }
    })

    // Handle Create New Store
    const handleAddNewStore = async () => {
        const storeDetailsRef = await firestore.collection('store-details')
        if (storeName === null || storeName.length < 1) {
            alert("Store Name Required")
        } else if (storeContact === null || storeContact.length < 1) {
            alert("Store Contact Required")
        } else if (storeGstin === null || storeGstin.length < 1) {
            alert("Store GSTIN Required")
        } else if (storeAddress === null || storeAddress.length < 1) {
            alert("Store Address Required")
        } else if (storeEmail === null || storeEmail.length < 1) {
            alert("Store Email Required")
        } else {
            await setLoadingSaveStore(false);
            await storeDetailsRef.add({
                storeId: `${storeNumber}`,
                storeName: `${storeName}`,
                storeContact: `${storeContact}`,
                storePhone: `${storePhone}`,
                storeGstin: `${storeGstin}`,
                storeAddress: `${storeAddress}`,
                storeEmail: `${storeEmail}`
            }).then(async () => await firestore.collection("storeCounter").doc("store-counter").update({ storeId: storeNumber + 1 }))
                .then(async () => await setLoadingSaveStore(true)).then(async () => await updateStoreDetails()).then(async () => await handleAddStoreClose())
        }
    }

    // Handle Transfer Out Items 
    const handleTransferOutStock = async () => {
        if (selectedStore === null || selectedStore.length < 1) {
            await alert("Please Select The Store")
        } else if (handlerName === null || handlerName.length < 1) {
            await alert("Please Input The Name of the handler")
        } else if (selectedProducts.length === 0) {
            await alert("No items selected for transfer")
        } else {
            await setLoadingSaveStore(false);
            // Update Product Inventory Counts
            await selectedProducts.map(async (singelProd) => {
                await firestore.collection("warehouse-products-bng").where('itemId', '==', singelProd.itemId).limit(1).get().then(async (query) => {
                    const product = await query.docs[0];
                    var currVal = Number(product.data().availableQuantity);
                    var minus = Number(singelProd.selectedQuantity);
                    await product.ref.update({
                        availableQuantity: currVal - minus
                    });
                }).catch((e) => console.log(e))
            });

            // Send Product to Store
            await selectedProducts.map(async (singelProd) => {
                const docRef = await firestore.collection("physical-stores").doc(`${selectedStore.toLowerCase()}`).collection("store-inventory");
                var productFound = false;
                var docId = "";
                docRef.get().then((snapshot) => {
                    snapshot.docs.map(async doc => {
                        if (doc.data().itemId === singelProd.itemId) {
                            docId = doc.id;
                            productFound = true;
                        }
                    }
                    )
                }).then(async () => {
                    if (productFound === true) {
                        await firestore.collection("physical-stores").doc(`${selectedStore.toLowerCase()}`).collection("store-inventory").doc(`${docId}`)
                            .update({
                                availableQuantity: firebase.firestore.FieldValue.increment(Number(singelProd.selectedQuantity)),
                                purchasePrice: Number(singelProd.purchasePrice) + Number(singelProd.transferCost),
                                itemMrp: Number(singelProd.itemMrp),
                                itemMsp: Number(singelProd.itemMsp),
                                stockTransferPrice: Number(singelProd.transferCost)
                            });
                    } else {
                        firestore.collection("physical-stores").doc(`${selectedStore.toLowerCase()}`).collection("store-inventory").add({
                            name: singelProd.name,
                            itemCategory: singelProd.itemCategory,
                            purchasePrice: Number(singelProd.purchasePrice) + Number(singelProd.transferCost),
                            itemMrp: Number(singelProd.itemMrp),
                            itemMsp: Number(singelProd.itemMsp),
                            itemMspB2b: Number(singelProd.itemMspB2b ? singelProd.itemMspB2b : 0),
                            stockTransferPrice: Number(singelProd.transferCost),
                            b2bPrice: Number(singelProd.b2bPrice ? singelProd.b2bPrice : 0),
                            homeDeliveryB2c: Number(singelProd.homeDeliveryB2c ? singelProd.homeDeliveryB2c : 0),
                            institutionalB2c: Number(singelProd.institutionalB2c ? singelProd.institutionalB2c : 0),
                            walkInB2c: Number(singelProd.walkInB2c ? singelProd.walkInB2c : 0),
                            itemMeasure: singelProd.itemMeasure,
                            availableQuantity: Number(singelProd.selectedQuantity),
                            itemGst: Number(singelProd.itemGst),
                            itemHsn: singelProd.itemHsn,
                            itemId: singelProd.itemId,
                        })
                    }
                }).then(async () => {
                    await firestore.collection("warehouse-products-bng").where('itemId', '==', singelProd.itemId).limit(1).get().then(async (query) => {
                        const product = await query.docs[0];
                        // var currVal = thing.data().value;
                        // const newVal = currVal - minus;
                        await product.ref.update({
                            purchasePrice: Number(singelProd.originalPurchasePrice),
                            itemMrp: Number(singelProd.itemMrp),
                            stockTransferPrice: Number(singelProd.transferCost)
                        });
                    }).catch((e) => console.log(e))
                })
            }
            )
            // await selectedProducts.map(async (singelProd) => {
            //     await firestore.collection("physical-stores").doc(`${selectedStore.toLowerCase()}`).collection("store-inventory").where('itemId', '==', singelProd.itemId).limit(1).get()
            //         // await firestore.collection("warehouse-products").where('itemId', '==', singelProd.itemId).limit(1).get()
            //         .then((query) => {
            //             if (query.docs[0] === undefined) {
            //                 firestore.collection("physical-stores").doc(`${selectedStore.toLowerCase()}`).collection("store-inventory").add({
            //                     name: singelProd.name,
            //                     itemCategory: singelProd.itemCategory,
            //                     purchasePrice: Number(singelProd.purchasePrice) + Number(singelProd.transferCost),
            //                     itemMrp: Number(singelProd.itemMrp),
            //                     itemMsp: Number(singelProd.itemMsp),
            //                     itemMspB2b: Number(singelProd.itemMspB2b ? singelProd.itemMspB2b : 0),
            //                     stockTransferPrice: Number(singelProd.transferCost),
            //                     b2bPrice: Number(singelProd.b2bPrice ? singelProd.b2bPrice : 0),
            //                     homeDeliveryB2c: Number(singelProd.homeDeliveryB2c ? singelProd.homeDeliveryB2c : 0),
            //                     institutionalB2c: Number(singelProd.institutionalB2c ? singelProd.institutionalB2c : 0),
            //                     walkInB2c: Number(singelProd.walkInB2c ? singelProd.walkInB2c : 0),
            //                     itemMeasure: singelProd.itemMeasure,
            //                     availableQuantity: Number(singelProd.selectedQuantity),
            //                     itemGst: Number(singelProd.itemGst),
            //                     itemHsn: singelProd.itemHsn,
            //                     itemId: singelProd.itemId,
            //                 })
            //             } else {
            //                 const product = query.docs[0];
            //                 product.ref.update({
            //                     availableQuantity: Number(product.data().availableQuantity) + Number(singelProd.selectedQuantity),
            //                     purchasePrice: Number(singelProd.purchasePrice) + Number(singelProd.transferCost),
            //                     itemMrp: Number(singelProd.itemMrp),
            //                     itemMsp: Number(singelProd.itemMsp),
            //                     stockTransferPrice: Number(singelProd.transferCost)
            //                 });

            //             }
            //         }).then(async () => {
            //             await firestore.collection("warehouse-products").where('itemId', '==', singelProd.itemId).limit(1).get().then(async (query) => {
            //                 const product = await query.docs[0];
            //                 // var currVal = thing.data().value;
            //                 // const newVal = currVal - minus;
            //                 await product.ref.update({
            //                     purchasePrice: Number(singelProd.originalPurchasePrice),
            //                     itemMrp: Number(singelProd.itemMrp),
            //                     stockTransferPrice: Number(singelProd.transferCost)
            //                 });
            //             }).catch((e) => console.log(e))
            //         })
            // });
            await firestore.collection("warehouse-logs-transfer-out-bng").add({
                transferOutId: `CAT-TR-OUT-${transferOutId}`,
                toStore: `${selectedStore.toLowerCase()}`,
                addedBy: `${handlerName.toLowerCase()}`,
                productsUpdated: selectedProducts,
                timeUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
            await setTimeout(async () => { await setLoadingSaveStore(true); await setCanRefresh(true) }, 6000);
        }
    }

    // Handle Add new Handler
    const handleAddNewHandler = async () => {
        if (addHandlerName.length < 1) {
            await alert("Please input full name of the handler")
        } else {
            await firestore.collection("stock-handlers").add({ name: addHandlerName }).then(async () => await updateHandlerData()).then(async () => await handleCloseHandlerModal())
            // await console.log(addHandlerName)
        }
    }

    // Scan func and states
    const handleScan = async (data) => {
        await setScanData(null);
        await setScanData(data);
        // await setScanItem(false);
    }

    const handleError = async (err) => {
        await alert("Error Occured When Scanning", err)
    }

    // Effect to generate random code
    useEffect(() => {
        const onLoadGenRandCode = async () => {
            let randomCode = '';
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (var i = 8; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
            await setTransferOutId(randomCode);
        };
        onLoadGenRandCode();
    }, [])

    const updateWarehouseProductsInner = () => {
        updateWarehouseData()
    }

    return (
        <div className='transfer-stock-main-container'>
            <h3>Transfer-Out Stock Entry</h3>
            <div className='stock-in-form'>
                <div style={{ width: "50%" }}>
                    <Form.Label><h4>1. Transfer Item/s To Store</h4></Form.Label>
                    <Form.Control as="select" onChange={(event) => setSelectedStore(event.target.value.toLowerCase())} >
                        <option>{null}</option>
                        {/* {catStoreList.map((store, i) =>
                            <option key={i} value={`${store}`} >{store.toLowerCase()}</option>
                        )
                        } */}
                        <option value="bangalore store" >bangalore store</option>
                    </Form.Control>
                    <Button onClick={() => handleAddStoreShow()} style={{ fontSize: "0.8rem", margin: "10px 0px" }}>Add New Store</Button>
                </div>
                <div style={{ width: "50%", marginTop: "20px" }}>
                    <Form.Label><h4>2. Item/s Transferred By</h4></Form.Label>
                    <Form.Control as="select" onChange={(event) => setHandlerName(event.target.value.toLowerCase())} >
                        <option>{null}</option>
                        {stockHandlers.map((name, i) =>
                            <option key={i + 1} value={`${name.name}`} >{name.name.toLowerCase()}</option>
                        )
                        }
                    </Form.Control>
                    <Button variant="warning" onClick={() => handleShowHandlerModal()} style={{ fontSize: "0.8rem", margin: "10px 0px" }}>Add Stock Handler</Button>
                </div>
                <div style={{ width: "75%", marginTop: "20px" }}>
                    <Form.Label><h4>3. Select Product/s</h4></Form.Label>
                    <Button variant="info" style={{ fontSize: "0.8rem", margin: "10px 25px" }} onClick={() => handleSelectProductsShow()}>Select</Button>
                    <div >
                        <Table style={{ fontSize: "0.8rem" }} striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product ID</th>
                                    <th>Name</th>
                                    <th>PP original</th>
                                    <th>Transfer price</th>
                                    <th>PP Adjusted</th>
                                    <th>Available Quantity</th>
                                    <th>Selected Quantity</th>
                                    <th>Measure</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            {selectedProducts.length > 0 ?
                                <tbody>
                                    {selectedProducts.map((item, i) =>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item.itemId}</td>
                                            <td>{item.name}</td>
                                            <td>{item.originalPurchasePrice}</td>
                                            <td>{item.transferCost}</td>
                                            <td>{Number(Number(item.purchasePrice) + Number(item.transferCost))}</td>
                                            <td>{item.availableQuantity}</td>
                                            <td>{item.selectedQuantity}</td>
                                            <td>{item.itemMeasure}</td>
                                            <td style={{ textAlign: "center" }}><span style={{ fontSize: "1.3rem", cursor: "pointer" }} onClick={async () => {
                                                const itemsRemovedOnClick = await selectedProducts.filter(selectedItem => selectedItem.name !== item.name);
                                                await setSelectedProducts(itemsRemovedOnClick)
                                            }} > &#10062; </span></td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="10">No Items Selected</td>
                            }
                        </Table>
                    </div>
                </div>
                {canRefresh === false ?
                    <div style={{ width: "50%", marginTop: "20px" }}>
                        {loadingSaveStore === true ?
                            <Button variant="info" onClick={() => handleTransferOutStock()}>Transfer-Out The Selected Items</Button>
                            :
                            <Button variant="secondary" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </Button>
                        }
                    </div> : <Button variant="success" onClick={async () => await window.location.reload(false)} >Refresh Inventory</Button>}
            </div>

            {/* Add Store Modal */}
            <Modal show={addStoreShow} onHide={handleAddStoreClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h3>Add New Store</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <input style={{ marginBottom: "10px" }} type='text' placeholder='Store Name' onChange={(event) => setStoreName(event.target.value)}></input>
                        <input style={{ marginBottom: "10px" }} type='text' placeholder='Store Contact Name' onChange={(event) => setStoreContact(event.target.value)}></input>
                        <input style={{ marginBottom: "10px" }} type='text' placeholder='Store Phone' onChange={(event) => setStorePhone(event.target.value)}></input>
                        <input style={{ marginBottom: "10px" }} type='text' placeholder='Store GSTIN' onChange={(event) => setStoreGstin(event.target.value)}></input>
                        <input style={{ marginBottom: "10px" }} type='email' placeholder='Store Email' onChange={(event) => setStoreEmail(event.target.value)}></input>
                        <textarea style={{ marginBottom: "10px" }} rows="4" cols="50" placeholder='Store Address' onChange={(event) => setStoreAddress(event.target.value)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loadingSaveStore === true ?
                        <Button variant="info" onClick={() => handleAddNewStore()}> Save Store </Button>
                        :
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Add Handler Name */}
            <Modal show={showHandlerModal} onHide={handleCloseHandlerModal} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Handler Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl style={{ width: '250px' }} type='text' placeholder="Handler Full Name" onChange={(event) => setAddHandlerName(event.target.value.toLowerCase())} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleAddNewHandler()}>
                        Add New Handler
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Select Products Modal */}
            <Modal show={selectProductsShow} onHide={handleSelectProductsClose} centered backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Form.Group>
                        {scanItem === false ?
                            <Button variant={"danger"} onClick={() => setScanItem(true)} style={{ fontSize: "0.7rem", marginRight: "20px" }} >Scan Barcode</Button>
                            : <span style={{ marginRight: "20px", color: "red" }}><Spinner animation="grow" variant="secondary" size="sm" /> Scan Barcode Now...
                                <span style={{ color: "orange", cursor: "pointer" }} onClick={() => setScanItem(false)}>  &#10060;</span>
                            </span>
                        }
                    </Form.Group>
                    <input type='search' style={{ width: "60%" }} placeholder='Search Product by Name / Barcode Number' onFocus={() => { setScanItem(false); setScanData(null) }} onChange={(event) => setProductSearch(event.target.value)} />
                    <Button variant='primary' style={{ fontSize: "0.7rem", marginLeft: "20px" }} onClick={() => setAddProductModalShow(true)}>Add New Product</Button>
                    <AddProductModal categoryMeta={categoryMeta} show={addProductModalShow} key={21} onHide={() => setAddProductModalShow(false)} updateWarehouseProductsInner={() => updateWarehouseProductsInner()} updateCategoryData={() => updateCategoryData()} />
                    {scanItem === true ?
                        <BarcodeReader
                            onError={handleError}
                            onScan={handleScan}
                        // onKeyDetect={handleScan}
                        /> : null}
                </Modal.Header>
                <Modal.Body style={{ minHeight: "50vh", maxHeight: "70vh", overflowY: "Scroll" }}>
                    <Table striped bordered hover size="sm" style={{ fontSize: "0.8rem" }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product ID</th>
                                <th>Name</th>
                                <th>Available Quantity</th>
                                <th>Input Qty.</th>
                                <th>Purchase Price</th>
                                <th>MRP</th>
                                <th>MSP</th>
                                <th>Transfer Price in ₹</th>
                                <th>Measure</th>
                                <th>Add</th>
                            </tr>
                        </thead>
                        {warehouseProducts.length > 0 ?
                            <tbody>
                                {filteredProducts.slice(0, 100).map((item, i) =>

                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.itemId}</td>
                                        <td>{item.name}</td>
                                        <td style={{ maxWidth: "50px" }}>{Number(item.availableQuantity).toFixed(2)}</td>
                                        <td><Form.Control type="number" placeholder="Qty." onChange={(e) => setItemQuantity(e.target.value)} /></td>
                                        <td><Form.Control type="number" placeholder={`${item.purchasePrice}`} onChange={(e) => setItemPurchasePrice(e.target.value)} /></td>
                                        <td><Form.Control type="number" placeholder={`${item.itemMrp}`} onChange={(e) => setItemMrp(e.target.value)} /></td>
                                        <td><Form.Control type="number" placeholder={`${item.itemMsp}`} onChange={(e) => setItemMsp(e.target.value)} /></td>
                                        <td><Form.Control type="number" placeholder={`${item.stockTransferPrice ? item.stockTransferPrice : "0"}`} onChange={(e) => setTransferCost(e.target.value)} /></td>
                                        <td>{item.itemMeasure}</td>
                                        <td style={{ textAlign: "center" }}>
                                            {!selectedProducts.find((selItem) => selItem.itemId === item.itemId) ?
                                                <Button style={{ fontSize: "0.7rem" }} variant={"info"} onClick={async () => {
                                                    if (itemQuantity <= 0) {
                                                        await alert("Enter Item Quantity or you selected an invalid quantity")
                                                    } else if (selectedProducts.find((selItem) => selItem.itemId === item.itemId)) {
                                                        await alert("item Already Added")
                                                    } else if (Number(itemQuantity) > Number(item.availableQuantity)) {
                                                        await alert("Available Stock Quantity exeeds the Selected Quantity for this Item")
                                                    }
                                                    // else if (itemMsp && itemMsp < Number(item.purchasePrice)) {
                                                    //     await alert("Input MSP is lower than the purchase price")
                                                    // } else if (itemMsp && itemMsp > Number(item.itemMrp)) {
                                                    //     await alert("Input MSP is greater than MRP")
                                                    // } else if (itemMrp && itemMrp < Number(item.purchasePrice)) {
                                                    //     await alert("Input MRP is lesser than Purchase Price")
                                                    // }
                                                    else {
                                                        await setSelectedProducts([...selectedProducts, {
                                                            itemId: item.itemId,
                                                            name: item.name,
                                                            availableQuantity: item.availableQuantity,
                                                            itemCategory: item.itemCategory,
                                                            itemGst: Number(item.itemGst),
                                                            beforeDc: item.availableQuantity,
                                                            afterDc: Number(item.availableQuantity) - Number(itemQuantity),
                                                            itemHsn: item.itemHsn,
                                                            itemMrp: itemMrp ? Number(itemMrp) : Number(item.itemMrp),
                                                            itemMsp: itemMrp ? Number(itemMsp) : Number(item.itemMsp),
                                                            originalPurchasePrice: itemPurchasePrice ? Number(itemPurchasePrice) : Number(item.purchasePrice),
                                                            transferCost: transferCost ? Number(transferCost) : item.stockTransferPrice ? Number(item.stockTransferPrice) : 0,
                                                            purchasePrice: itemPurchasePrice ? Number(itemPurchasePrice) : Number(item.purchasePrice),
                                                            selectedQuantity: Number(itemQuantity),
                                                            itemMeasure: item.itemMeasure
                                                        }])
                                                        setItemQuantity(null);
                                                        setItemPurchasePrice(null);
                                                        setTransferCost(null);
                                                        setItemMrp(null);
                                                        setItemMsp(null);
                                                    }
                                                }}>Add Item</Button>
                                                :
                                                <span style={{ cursor: "pointer", fontSize: "1.3rem" }} onClick={async () => {
                                                    const itemsRemovedOnClick = await selectedProducts.filter(selectedItem => selectedItem.name !== item.name);
                                                    await setSelectedProducts(itemsRemovedOnClick)
                                                }}>&#10062;</span>}</td>
                                    </tr>
                                )
                                }
                            </tbody> :
                            <tbody><tr><td colSpan="9" ><Spinner animation="border" size="sm" /></td></tr></tbody>
                        }
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => handleSelectProductsClose()} >Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TransferOut;