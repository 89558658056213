import React, { useEffect, useState, useRef } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import './product-card.styles.scss';
import { firestore } from '../../../../firebase/firebase.utils';
import BarcodeGenerator from './barcode-generator.component';
import { useReactToPrint } from 'react-to-print';

import EditProductModal from '../manage-product-modals/edit-product-modal.component';

const ProductCard = (props) => {
    const [editProductModalShow, setEditProductModalShow] = useState(false);
    const [deleteLoadState, setdeleteLoadState] = useState(true);

    // barcode states
    const [showBarcodeModal, setShowBarcodeModal] = useState(false);

    const handleBarcodeModalClose = () => setShowBarcodeModal(false);
    const handleBarcodeModalShow = () => setShowBarcodeModal(true);

    // Handle Delete Product
    const handleDelete = async (itemId) => {
        await setdeleteLoadState(false);
        const docRefDelete = await firestore.collection("warehouse-products");
        docRefDelete.get().then(async (snapshot) => {
            let docId = '';
            await snapshot.docs.map(doc => doc.data().itemId === itemId ? docId = doc.id : null);
            await firestore.collection("warehouse-products").doc(`${docId}`).delete()
                .then(() => setdeleteLoadState(true))
                .then(() => window.location.reload(false))
        })
    }

    const [bbBlocks, setbbBlocks] = useState([]);

    const addLayout = () => {
        let blockArr = [];
        setbbBlocks([...bbBlocks, <BarcodeGenerator itemId={props.itemId} itemName={props.name} itemMrp={props.itemMrp} itemMsp={props.itemMsp} />,
        <BarcodeGenerator itemId={props.itemId} itemName={props.name} itemMrp={props.itemMrp} itemMsp={props.itemMsp} />]);
    }

    // Component Ref to print and print function
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <Card style={{ width: '27rem', marginRight: "20px", marginTop: "20px", boxShadow: '5px 5px 10px gray', borderRadius: '10px' }}>
                <Card.Body>
                    <Card.Title>{props.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Category : {props.itemCategory}</Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">Product ID : {props.itemId}</Card.Subtitle>
                    <Card.Text className='card-inner-content' style={{ display: 'flex', flexWrap: 'wrap' }}>

                        {/* <span style={{ marginRight: '20px' }}><b>Purchase Price:</b> {props.purchasePrice} ₹</span>
                    <span style={{ marginRight: '20px' }}><b>MRP:</b> {props.itemMrp} ₹</span>
                    <span style={{ marginRight: '20px' }}><b>MSP:</b> {props.itemMsp} ₹</span><br /> */}
                        <span style={{ marginRight: '20px' }}><b>Stock Available:</b> {props.availableQuantity === null ? "0" : Number(props.availableQuantity).toFixed(4)} {props.itemMeasure}</span><br />
                        <span style={{ marginRight: '20px' }}><b>GST:</b> {props.itemGst} %</span>
                        <span style={{ marginRight: '20px' }}><b>HSN:</b> {props.itemHsn.length !== 0 ? props.itemHsn : "NA"}</span>

                    </Card.Text>
                    {props.showEditDelete ? <Card.Link href="#Edit-Item" onClick={async () => await setEditProductModalShow(true)}> Edit</Card.Link> : null}
                    <EditProductModal
                        show={editProductModalShow}
                        categoryMeta={props.categoryMeta}
                        onHide={() => setEditProductModalShow(false)}
                        name={props.name}
                        itemCategory={props.itemCategory}
                        itemId={props.itemId}
                        purchasePrice={props.purchasePrice}
                        itemMrp={props.itemMrp}
                        itemMsp={props.itemMsp}
                        itemMspB2b={props.itemMspB2b}
                        stockTransferPrice={props.stockTransferPrice}
                        b2bPrice={props.b2bPrice}
                        homeDeliveryB2c={props.homeDeliveryB2c}
                        institutionalB2c={props.institutionalB2c}
                        walkInB2c={props.walkInB2c}
                        availableQuantity={props.availableQuantity}
                        itemMeasure={props.itemMeasure}
                        itemGst={props.itemGst}
                        itemHsn={props.itemHsn}
                    />
                    {props.showEditDelete ?
                        <>
                            {deleteLoadState === true ?
                                <Card.Link href="#Delete-Item" onClick={() => handleDelete(props.itemId)}>Delete</Card.Link>
                                : <Card.Link href="#Delete-Item-State" > Deleting...</Card.Link>}
                        </> : null}
                    <Card.Link href="#barcode-Item" onClick={() => { setbbBlocks([]); handleBarcodeModalShow() }}>Generate Barcode</Card.Link>
                </Card.Body>
            </Card>
            {/* Barcode Generator */}
            <Modal size="md" show={showBarcodeModal} onHide={handleBarcodeModalClose}>
                <Modal.Header closeButton>
                    <h5>Print Barcode</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-wrapper-body">
                        <div className="buttons-barcode">
                            <Button size="sm" onClick={() => addLayout()} >Add +2</Button>
                            <Button size="sm" variant="success" onClick={() => handlePrint()}>Print Barcodes</Button>
                        </div>
                        {bbBlocks.length > 0 ?
                            <div ref={componentRef} className="content-wrapper-print">
                                {bbBlocks.map((block) =>
                                    <>
                                        {block}
                                    </>
                                )}
                            </div>
                            :
                            <div ref={componentRef} className="content-wrapper-print">
                                <BarcodeGenerator itemId={props.itemId} itemName={props.name} itemMrp={props.itemMrp} itemMsp={props.itemMsp} />
                                <BarcodeGenerator itemId={props.itemId} itemName={props.name} itemMrp={props.itemMrp} itemMsp={props.itemMsp} />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleBarcodeModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProductCard;