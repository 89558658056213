import React, { useState, useEffect, useRef } from 'react';
import './transfer-out-report.styles.scss';
import { Form, Spinner, Table, Button, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from '../../firebase/firebase.utils';
import firebase from 'firebase/app';
import { useReactToPrint } from 'react-to-print';
import { catStoreList } from '../../res/catmart-list';


const TransferOutReport = ({ storeDetails }) => {
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    // State to store the warehouse logs
    const [warehouseLogs, setWarehouseLogs] = useState([]);
    const [wareHouseProfit, setWareHouseProfit] = useState(0);
    const [loading, setLoading] = useState(false);
    // State to save store name
    const [storeName, setStoreName] = useState("all");

    // Verticle invoice modal states and logic
    const [verInvoiceModalShow, setVerInvoiceModalShow] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null)

    const handleVerInvoiceModalClose = () => setVerInvoiceModalShow(false);
    const handleVerInvoiceModalShow = () => setVerInvoiceModalShow(true);

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            setLoading(true)
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("warehouse-logs-transfer-out").orderBy('timeUpdated', "desc")
                .where('timeUpdated', '>=', start).where('timeUpdated', '<=', end);
            docRef.get().then(async (snapshot) => {
                let warehouseLogsArray = [];
                await snapshot.docs.map(doc => warehouseLogsArray.push(doc.data()))
                await setWarehouseLogs(warehouseLogsArray)
            })
            setLoading(false)
        };
        fetchLogData();
    }, [value1, value2])

    const filteredLogs = warehouseLogs.filter(item => {
        if (storeName === "all") {
            return item.toStore.toLowerCase().includes(" ");
        } else {
            return item.toStore.toLowerCase().includes(storeName.toLowerCase());
        }
    })

    // calculate selected values
    useEffect(() => {
        const calculateLogData = async () => {
            setLoading(true)
            // calculate gross profit
            let calculatedArray = [];
            filteredLogs.map((entry, i) => {
                entry.productsUpdated.map((product, i) => {
                    if (product.transferCost) {
                        return calculatedArray.push(Number(product.transferCost))
                    } else {
                        return calculatedArray.push(Number(0))
                    }
                })
            })
            setWareHouseProfit(calculatedArray.reduce((accPrice, item) => accPrice + Number(item), 0).toFixed(2));
            setLoading(false)
        };
        calculateLogData();
    }, [storeName, value1, value2])

    // Component Ref to print and print function
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className='purchase-report-main-container'>
            <h3>Transfer-Out Report</h3>
            <span className="wh-gross-profit-box-view">
                <h6>Warehouse Gross Profit (selected range)</h6>
                <h2>{Number(wareHouseProfit).toFixed(2)} INR</h2>
            </span>
            <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
            <div className='purchase-report-date-range-selectors'>
                <div>
                    <span><b>Select From Date : </b></span>
                    <DatePicker className='purchase-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div>
                    <span><b>Select To Date : </b></span>
                    <DatePicker className='purchase-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div style={{ display: "flex" }}>
                    <span><b>Transferred To Store : </b></span>
                    <Form.Control style={{ marginLeft: "20px", width: "400px" }} as="select" onChange={(e) => setStoreName(e.target.value.toLowerCase())} >
                        <option selected>all</option>
                        <option>internal transfer</option>
                        <option>warehouse bangalore</option>
                        {catStoreList.map((item, i) =>
                            <option> {item.toLowerCase()}</option>
                        )}
                    </Form.Control>
                </div>
            </div>
            <div className="purchase-report-table-view">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="purchase-table-to-xls"
                    filename="transfer-in-report"
                    sheet="tablexls"
                    buttonText="Export to XLS" />
                <Table striped bordered hover size="sm" id="purchase-table-to-xls" style={{ fontSize: "0.9rem" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Transfer-Out ID</th>
                            <th>Time Of Entry</th>
                            <th>Transferred To</th>
                            <th>Transferred By</th>
                            <th>Transferred Products</th>
                            <th>Print DC</th>
                        </tr>
                    </thead>
                    {warehouseLogs.length > 0 ?
                        <>
                            {filteredLogs.length !== 0 ?
                                <tbody>
                                    {filteredLogs.map((item, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.transferOutId}</td>
                                            <td>{`${item.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.toStore}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.addedBy}</td>
                                            <td>
                                                <Table striped bordered hover size="sm" style={{ fontSize: "0.8rem" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th style={{ backgroundColor: "#D4FFCA" }}>Stock Before DC</th>
                                                            <th>Transferred Quantity</th>
                                                            <th style={{ backgroundColor: "#FFCACA" }}>Stock After DC</th>
                                                            <th>Measure</th>
                                                            <th>Purchase Price</th>
                                                            <th>MRP</th>
                                                            <th>MSP</th>
                                                            <th>Transfer Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.productsUpdated.map((pro, i) =>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{pro.itemId}</td>
                                                                <td>{pro.name}</td>
                                                                <td style={{ backgroundColor: "#D4FFCA" }}>{pro.beforeDc ? Number(pro.beforeDc).toFixed(2) : "NA"}</td>
                                                                <td>{Number(pro.selectedQuantity).toFixed(2)}</td>
                                                                <td style={{ backgroundColor: "#FFCACA" }}>{pro.afterDc || pro.afterDc >= 0 || pro.afterDc == "0" ? Number(pro.afterDc).toFixed(2) : "NA"}</td>
                                                                <td>{pro.itemMeasure}</td>
                                                                <td>{pro.purchasePrice}</td>
                                                                <td>{pro.itemMrp}</td>
                                                                <td>{pro.itemMsp}</td>
                                                                <td>{pro.transferCost ? pro.transferCost : "No Data"}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </td>
                                            <td><Button variant="info" style={{ fontSize: "0.7rem" }} onClick={async () => {
                                                await setSelectedLog(item);
                                                handleVerInvoiceModalShow();
                                            }} >Print DC</Button></td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="6">No Logs Found</td>
                            }
                        </>
                        :
                        <td colSpan="6"><Spinner variant="info" size="sm" animation="border" /></td>
                    }
                </Table>
            </div>
            {/* Print Preview Modal */}
            <Modal backdrop="static" keyboard={false} show={verInvoiceModalShow} onHide={handleVerInvoiceModalClose} size="xl">
                <Modal.Header closeButton>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <h4>Print Preview</h4>
                        <Button variant="warning" onClick={() => handlePrint()}>Print Delivery Challan</Button>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
                    {selectedLog ?
                        <div ref={componentRef} className="verticle-invoice-main-container">
                            <div className="verticle-invoice-inner-container">
                                <div className="shop-info" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width="70%"></img>
                                    <h1 style={{ textAlign: "center" }}>KODAGU TRADE AND COMMERCE P Ltd.</h1>
                                    <span style={{ textAlign: "center", fontSize: "2.5rem" }}>#1 Block No. 14, Ground Floor, Shri Shambavananda Complex, Malbar road, Virajpet - 571219, Karnataka</span>
                                    <span style={{ textAlign: "center", fontSize: "2.5rem" }}>info@catkodagu.com, 6366922199,6633922190</span>
                                    <h6 style={{ textAlign: "center", fontSize: "2.5rem" }}>GSTIN : 29AAICK2158E1ZH</h6>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="shop-info2">
                                    <h1>Delivery Challan</h1>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <span style={{ fontSize: "1.8rem" }}><b>DC ID. : {selectedLog.transferOutId}</b></span>
                                        <span style={{ fontSize: "1.8rem" }}><b>Date : {`${selectedLog.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</b></span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <span style={{ fontSize: "1.8rem" }}>Transferred To Delivery Center: {selectedLog.toStore.toUpperCase()}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <span style={{ fontSize: "1.8rem" }}>Transfer Made By : {selectedLog.addedBy.toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="product-table">
                                    <Table bordered size="sm" style={{ fontSize: "2.3rem", marginTop: "20px", color: "black" }}>
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th style={{ fontSize: "1.8rem" }}>#</th>
                                                <th style={{ fontSize: "1.8rem" }}>Product ID</th>
                                                <th style={{ fontSize: "1.8rem" }}>Item</th>
                                                <th style={{ fontSize: "1.8rem" }}>Unit Price</th>
                                                <th style={{ fontSize: "1.8rem" }}>Quantity</th>
                                                <th style={{ fontSize: "1.8rem" }}>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedLog.productsUpdated.map((item, i) =>
                                                <tr key={i + 1} style={{ textAlign: "center" }} >
                                                    <td>{i + 1}</td>
                                                    <td>{item.itemId}</td>
                                                    <td>{item.name}</td>
                                                    <td>{(Number(item.purchasePrice) + (item.transferCost ? Number(item.transferCost) : 0)).toFixed(2)}</td>
                                                    <td>{item.selectedQuantity} {item.itemMeasure}</td>
                                                    <td>{((Number(item.purchasePrice) + (item.transferCost ? Number(item.transferCost) : 0)) * Number(item.selectedQuantity)).toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th colSpan="2">Total</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th>{selectedLog.productsUpdated.reduce((acc, item) => acc + ((Number(item.purchasePrice) + (item.transferCost ? Number(item.transferCost) : 0)) * Number(item.selectedQuantity)), 0).toFixed(2)}</th>
                                            </tr>
                                        </thead>
                                    </Table>
                                </div>
                                <div style={{ width: "100%", borderTop: "2px solid black", textAlign: "center" }} className="end-slip">
                                    {/* <h1 style={{ marginBottom: "30px", fontSize: "5rem" }}><b>Net Bill Amount : {Math.round(selectedLog.invoiceItems.reduce((accumalatedQuantity, selectedItem) => accumalatedQuantity + selectedItem.priceGstDiscount, 0)).toFixed(2)}</b></h1> */}
                                </div><br />
                                <div style={{ textAlign: "center", fontSize: "3rem", marginTop: "20px" }} className="thank-you-space">
                                    {/* <span>Thank You, Shop with us again!!</span> */}
                                </div>
                            </div>
                        </div> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handlePrint()}>Print DC</Button>
                    <Button variant="secondary" onClick={handleVerInvoiceModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TransferOutReport;