import React, { useEffect, useState } from 'react';
import './main-footer.styles.scss';
import { Navbar } from 'react-bootstrap';


const MainFooter = () => {
    return (
        <div className='wh-admin-footer-container'>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand>© 2020 Kodagu Trade & Commerce Pvt. Ltd</Navbar.Brand>
            </Navbar>
        </div>
    )
}

export default MainFooter;