import React, { useEffect, useState, Component, useRef } from 'react';
import './product-report.styles.scss';
import { Form, Table, Spinner, Button, Modal } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BarcodeReader from 'react-barcode-reader';
import { firestore } from '../../firebase/firebase.utils';
import { useReactToPrint } from 'react-to-print';


const ProductReport = ({ warehouseProducts }) => {
    const [searchProduct, setSearchProduct] = useState("");

    // Verticle invoice modal states and logic
    const [verInvoiceModalShow, setVerInvoiceModalShow] = useState(false);

    const handleVerInvoiceModalClose = () => setVerInvoiceModalShow(false);
    const handleVerInvoiceModalShow = () => setVerInvoiceModalShow(true);

    // Scan func and states
    const [scanItem, setScanItem] = useState(false);
    const [scanData, setScanData] = useState(null);

    // const filteredProducts = warehouseProducts.filter(item => {
    //     return item.name.toLowerCase().includes(searchProduct.toLowerCase());
    // })

    // Filter Search Products
    const filteredProducts = warehouseProducts.filter(item => {
        if (scanItem === false && scanData === null) {
            return item.name.toLowerCase().includes(searchProduct.toLowerCase()) || item.itemId.toLowerCase().includes(searchProduct.toLowerCase());
        } else if (searchProduct.length > 0) {
            return item.name.toLowerCase().includes(searchProduct.toLowerCase());
        } else {
            return item.itemId.toLowerCase().includes(scanData === null ? "" : scanData.toString().toLowerCase());
        }
    })

    // Scan func and states
    const handleScan = async (data) => {
        await setScanData(null);
        await setScanData(data);
        await setScanItem(false);
    }

    const handleError = async (err) => {
        await alert("Error Occured When Scanning", err)
    }

    // Component Ref to print and print function
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className='product-report-main-container'>
            {scanItem === true ?
                <BarcodeReader
                    onError={handleError}
                    onScan={handleScan}
                // onKeyDetect={handleScan}
                /> : null}
            <div className="glossary-view">
                <span className="box-view">
                    <h6>Total Product Types</h6>
                    <h1>{warehouseProducts.length}</h1>
                </span>
                <span className="box-view">
                    <h6>Total Purchase Cost</h6>
                    <h2>{warehouseProducts.reduce((accumalatedQuantity, product) => accumalatedQuantity + (Number(product.purchasePrice) * Number(product.availableQuantity)), 0).toFixed(2)} INR</h2>
                </span>
                <span className="box-view">
                    <h6>Total Product MRP</h6>
                    <h2>{warehouseProducts.reduce((accumalatedQuantity, product) => accumalatedQuantity + (Number(product.itemMrp) * Number(product.availableQuantity)), 0).toFixed(2)} INR</h2>
                </span>
                <span className="box-view">
                    <h6>Total Product MSP</h6>
                    <h2>{warehouseProducts.reduce((accumalatedQuantity, product) => accumalatedQuantity + (Number(product.itemMsp) * Number(product.availableQuantity)), 0).toFixed(2)} INR</h2>
                </span>
            </div>
            <div className='search-view'>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label><h4>Search For Product</h4></Form.Label>
                    <Form.Control type="search" placeholder="Search Product by Name / Barcode Number" onChange={(event) => setSearchProduct(event.target.value)} />
                </Form.Group>
                {scanItem === false ?
                    <Button variant={"danger"} onClick={() => setScanItem(true)} style={{ fontSize: "0.7rem", marginRight: "20px" }} >Scan Barcode</Button>
                    : <span style={{ marginRight: "20px", color: "red" }}><Spinner animation="grow" variant="secondary" size="sm" /> Scan Barcode Now...
                        <span style={{ color: "orange", cursor: "pointer" }} onClick={() => setScanItem(false)}>  &#10060;</span>
                    </span>
                }
            </div>
            <div className='table-view'>
                <div>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename="product-avail-report"
                        sheet="tablexls"
                        buttonText="Export to XLS" />
                    <Button variant="success" size="sm" style={{ fontSize: "0.8rem", margin: "10px" }} onClick={async () => { handleVerInvoiceModalShow(); }} >Print Current Stock Position</Button>
                </div>
                <Table striped bordered hover size="sm" id="table-to-xls" responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product ID</th>
                            <th>Name</th>
                            <th>HSN</th>
                            <th>Purchase Price</th>
                            <th>MRP</th>
                            <th>MSP</th>
                            <th>Category</th>
                            <th>In Stock</th>
                            <th>Measure</th>
                            <th>GST %</th>
                        </tr>
                    </thead>
                    {warehouseProducts.length > 0 ?
                        <tbody>
                            {filteredProducts.map((item, i) =>
                                <tr key={i + 1} >
                                    <td>{i + 1}</td>
                                    <td>{item.itemId}</td>
                                    <td>{item.name}</td>
                                    <td>{item.itemHsn !== null && item.itemHsn.length > 0 ? item.itemHsn : "None"}</td>
                                    <td>{item.purchasePrice}</td>
                                    <td>{item.itemMrp}</td>
                                    <td>{item.itemMsp}</td>
                                    <td>{item.itemCategory}</td>
                                    <td>{item.availableQuantity !== null ? Number(item.availableQuantity).toFixed(2) : 0}</td>
                                    <td>{item.itemMeasure}</td>
                                    <td>{item.itemGst}</td>

                                </tr>
                            )}
                        </tbody> : <Spinner style={{ marginTop: "10px" }} animation="border" />}
                </Table>
            </div>
            {/* Print Preview Modal */}
            <Modal backdrop="static" keyboard={false} show={verInvoiceModalShow} onHide={handleVerInvoiceModalClose} size="xl">
                <Modal.Header closeButton>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <h4>Print Preview</h4>
                        <Button variant="warning" onClick={() => handlePrint()}>Print Stock Position</Button>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
                    {warehouseProducts ?
                        <div ref={componentRef} className="verticle-invoice-main-container">
                            <div className="verticle-invoice-inner-container">
                                <div className="shop-info" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width="70%"></img>
                                    <h1 style={{ textAlign: "center" }}>KODAGU TRADE AND COMMERCE P Ltd.</h1>
                                    <span style={{ textAlign: "center", fontSize: "2.5rem" }}>#1 Block No. 14, Ground Floor, Shri Shambavananda Complex, Malbar road, Virajpet - 571219, Karnataka</span>
                                    <span style={{ textAlign: "center", fontSize: "2.5rem" }}>info@catkodagu.com, 6366922199,6633922190</span>
                                    <h6 style={{ textAlign: "center", fontSize: "2.5rem" }}>GSTIN : 29AAICK2158E1ZH</h6>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="shop-info2">
                                    <h1>Realtime Stock Position - Warehouse</h1>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <span style={{ fontSize: "1.8rem" }}><b>Date : {new Date().toLocaleDateString()}</b></span>
                                        <span style={{ fontSize: "1.8rem" }}><b>Time : {new Date().toLocaleTimeString()}</b></span>
                                    </div>
                                </div>
                                <div className="product-table">
                                    <Table bordered size="sm" style={{ fontSize: "1.4rem", marginTop: "20px", fontWeight: "bolder", maxWidth: "100%" }}>
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th style={{ fontSize: "1.5rem" }}>#</th>
                                                <th style={{ fontSize: "1.5rem" }}>Product ID</th>
                                                <th style={{ fontSize: "1.5rem" }}>Name</th>
                                                <th style={{ fontSize: "1.5rem" }}>HSN</th>
                                                <th style={{ fontSize: "1.5rem" }}>Current Qty.</th>
                                                <th style={{ fontSize: "1.5rem" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {warehouseProducts.map((item, i) =>
                                                <tr key={i + 1} style={{ textAlign: "center" }} >
                                                    <td>{i + 1}</td>
                                                    <td>{item.itemId}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.itemHsn}</td>
                                                    <td>{Number(item.availableQuantity).toFixed(2)}</td>
                                                    <td>{item.itemMeasure}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{ width: "100%", borderTop: "2px solid black", textAlign: "center" }} className="end-slip">
                                    {/* <h1 style={{ marginBottom: "30px", fontSize: "5rem" }}><b>Net Bill Amount : {Math.round(selectedLog.invoiceItems.reduce((accumalatedQuantity, selectedItem) => accumalatedQuantity + selectedItem.priceGstDiscount, 0)).toFixed(2)}</b></h1> */}
                                </div><br />
                                <div style={{ textAlign: "center", fontSize: "3rem", marginTop: "20px" }} className="thank-you-space">
                                    {/* <span>Thank You, Shop with us again!!</span> */}
                                </div>
                            </div>
                        </div> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handlePrint()}>Print Stock Position</Button>
                    <Button variant="secondary" onClick={handleVerInvoiceModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ProductReport;