import React, { useState, useEffect } from 'react';
import './wastage-spillage.styles.scss'
import { Button, Modal, Form, Spinner, Table, FormControl } from 'react-bootstrap';
import { firestore } from '../../firebase/firebase.utils';
import firebase from 'firebase/app';
import AddProductModal from '../manage-products/manage-product-modals/add-product-modal.component';
import BarcodeReader from 'react-barcode-reader';

const WastageSpillage = ({ storeDetails, warehouseProducts, categoryMeta, stockHandlers, updateWarehouseData, updateHandlerData, updateCategoryData, updateStoreDetails }) => {
    const [wastageId, setWastageId] = useState('');

    const [selectProductsShow, setSelectProductsShow] = useState(false);
    const [handlerName, setHandlerName] = useState("");
    const [addHandlerName, setAddHandlerName] = useState("");
    const [canRefresh, setCanRefresh] = useState(false);
    // Loading Status States
    const [loadingSaveStore, setLoadingSaveStore] = useState(true);
    // Search Product State
    const [productSearch, setProductSearch] = useState("");
    // Add New Product Modal
    const [addProductModalShow, setAddProductModalShow] = useState(false);
    // Selected Products From Modal
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [itemQuantity, setItemQuantity] = useState(null);
    const [wastageReason, setWastageReason] = useState("None");
    // Scan func and states
    const [scanItem, setScanItem] = useState(false);
    const [scanData, setScanData] = useState(null);

    const handleSelectProductsClose = () => { setScanItem(false); setScanData(null); setSelectProductsShow(false) };
    const handleSelectProductsShow = () => setSelectProductsShow(true);
    // States and functions to add new handler
    const [showHandlerModal, setShowHandlerModal] = useState(false);

    const handleCloseHandlerModal = () => setShowHandlerModal(false);
    const handleShowHandlerModal = () => setShowHandlerModal(true);

    // Effect to generate random code
    useEffect(() => {
        const onLoadGenRandCode = async () => {
            let randomCode = '';
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (var i = 8; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
            await setWastageId(randomCode);
        };
        onLoadGenRandCode();
    }, [])

    // Filter Search Products
    const filteredProducts = warehouseProducts.filter(item => {
        if (scanItem === false && scanData === null) {
            return item.name.toLowerCase().includes(productSearch.toLowerCase()) || item.itemId.toLowerCase().includes(productSearch.toLowerCase());
        } else if (productSearch.length > 0) {
            return item.name.toLowerCase().includes(productSearch.toLowerCase());
        } else {
            return item.itemId.toLowerCase().includes(scanData === null ? "" : scanData.toString().toLowerCase());
        }
    })

    // Handle Transfer Out Items 
    const handleSubmitWastage = async () => {
        if (handlerName === null || handlerName.length < 1) {
            await alert("Please Input The Name of the handler")
        } else if (selectedProducts.length === 0) {
            await alert("No items selected for transfer")
        } else {
            await setLoadingSaveStore(false);
            // Update Product Inventory Counts
            await selectedProducts.map(async (singelProd) => {
                await firestore.collection("warehouse-products").where('itemId', '==', singelProd.itemId).limit(1).get().then(async (query) => {
                    const product = await query.docs[0];
                    // var currVal = thing.data().value;
                    // const newVal = currVal - minus;
                    await product.ref.update({ availableQuantity: Number(Number(product.data().availableQuantity) - Number(singelProd.selectedQuantity)) });
                })
            })
            await firestore.collection("warehouse-logs-wastage").add({
                wastageId: `CAT-WSTG-${wastageId}`,
                addedBy: `${handlerName.toLowerCase()}`,
                productsUpdated: selectedProducts,
                wastageReason: `{${wastageReason}}`,
                timeUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            })
            // Loading state change
            await setTimeout(async () => { await setLoadingSaveStore(true); await setCanRefresh(true) }, 6000);
        }
    }

    // Handle Add new Handler
    const handleAddNewHandler = async () => {
        if (addHandlerName.length < 1) {
            await alert("Please input full name of the handler")
        } else {
            await firestore.collection("stock-handlers").add({ name: addHandlerName }).then(async () => await updateHandlerData()).then(async () => await handleCloseHandlerModal())
            // await console.log(addHandlerName)
        }
    }

    // Scan func and states
    const handleScan = async (data) => {
        await setScanData(null);
        await setScanData(data);
        // await setScanItem(false);
    }

    const handleError = async (err) => {
        await alert("Error Occured When Scanning", err)
    }

    const updateWarehouseProductsInner = () => {
        updateWarehouseData()
    }

    return (
        <div className='wastage-main-container'>
            <h3>Add Wastage / Spillage</h3>
            <div className='stock-in-form'>
                <div style={{ width: "50%", marginTop: "20px" }}>
                    <Form.Label><h4>1. Added By</h4></Form.Label>
                    <Form.Control as="select" onChange={(event) => setHandlerName(event.target.value.toLowerCase())} >
                        <option>{null}</option>
                        {stockHandlers.map((name) =>
                            <option value={`${name.name}`} >{name.name.toLowerCase()}</option>
                        )
                        }
                    </Form.Control>
                    <Button variant="warning" onClick={() => handleShowHandlerModal()} style={{ fontSize: "0.8rem", margin: "10px 0px" }}>Add Stock Handler</Button>
                </div>
                <div style={{ width: "50%", marginTop: "20px" }}>
                    <Form.Label><h4>2. Wastage Reason/Remarks</h4></Form.Label>
                    <Form.Control as="textarea" rows={2} placeholder="Reason For Wastage (eg: spoilt items, damaged etc.)" onChange={(event) => setWastageReason(event.target.value.toLowerCase())} >
                    </Form.Control>
                </div>
                <div style={{ width: "50%", marginTop: "20px" }}>
                    <Form.Label><h4>3. Select Product/s</h4></Form.Label>
                    <Button variant="info" style={{ fontSize: "0.8rem", margin: "10px 25px" }} onClick={() => handleSelectProductsShow()}>Select</Button>
                    <div >
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product ID</th>
                                    <th>Name</th>
                                    <th>Purchase Price</th>
                                    <th>Selected Quantity</th>
                                    <th>Measure</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            {selectedProducts.length > 0 ?
                                <tbody>
                                    {selectedProducts.map((item, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.itemId}</td>
                                            <td>{item.name}</td>
                                            <td>{item.purchasePrice.toFixed(2)} INR</td>
                                            <td>{item.selectedQuantity}</td>
                                            <td>{item.itemMeasure}</td>
                                            <td style={{ textAlign: "center" }}><span style={{ fontSize: "1.3rem", cursor: "pointer" }} onClick={async () => {
                                                const itemsRemovedOnClick = await selectedProducts.filter(selectedItem => selectedItem.name !== item.name);
                                                await setSelectedProducts(itemsRemovedOnClick)
                                            }} > &#10062; </span></td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="8">No Items Selected</td>
                            }
                        </Table>
                    </div>
                </div>
                {canRefresh === false ?
                    <div style={{ width: "50%", marginTop: "20px" }}>
                        {loadingSaveStore === true ?
                            <Button variant="info" onClick={() => handleSubmitWastage()}>Create Wastage Log</Button>
                            :
                            <Button variant="secondary" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </Button>
                        }
                    </div> : <Button variant="success" onClick={async () => await window.location.reload(false)} >Refresh Inventory</Button>}
            </div>

            {/* Add Handler Name */}
            <Modal show={showHandlerModal} onHide={handleCloseHandlerModal} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Handler Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl style={{ width: '250px' }} type='text' placeholder="Handler Full Name" onChange={(event) => setAddHandlerName(event.target.value.toLowerCase())} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleAddNewHandler()}>
                        Add New Handler
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Select Products Modal */}
            <Modal show={selectProductsShow} onHide={handleSelectProductsClose} centered backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Form.Group>
                        {scanItem === false ?
                            <Button variant={"danger"} onClick={() => setScanItem(true)} style={{ fontSize: "0.7rem", marginRight: "20px" }} >Scan Barcode</Button>
                            : <span style={{ marginRight: "20px", color: "red" }}><Spinner animation="grow" variant="secondary" size="sm" /> Scan Barcode Now...
                                <span style={{ color: "orange", cursor: "pointer" }} onClick={() => setScanItem(false)}>  &#10060;</span>
                            </span>
                        }
                    </Form.Group>
                    <input type='search' style={{ width: "60%" }} placeholder='Search Product by Name / Barcode Number' onFocus={() => { setScanItem(false); setScanData(null) }} onChange={(event) => setProductSearch(event.target.value)} />
                    <Button variant='primary' style={{ fontSize: "0.7rem", marginLeft: "20px" }} onClick={() => setAddProductModalShow(true)}>Add New Product</Button>
                    <AddProductModal categoryMeta={categoryMeta} show={addProductModalShow} key={21} onHide={() => setAddProductModalShow(false)} updateWarehouseProductsInner={() => updateWarehouseProductsInner()} updateCategoryData={() => updateCategoryData()} />
                    {scanItem === true ?
                        <BarcodeReader
                            onError={handleError}
                            onScan={handleScan}
                        // onKeyDetect={handleScan}
                        /> : null}
                </Modal.Header>
                <Modal.Body style={{ minHeight: "50vh", maxHeight: "70vh", overflowY: "Scroll" }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product ID</th>
                                <th>Name</th>
                                <th>Avail. Quantity</th>
                                <th>Input Quantity</th>
                                <th>Measure</th>
                                <th>Add</th>
                            </tr>
                        </thead>
                        {warehouseProducts.length > 0 ?
                            <tbody>
                                {filteredProducts.slice(0, 100).map((item, i) =>

                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.itemId}</td>
                                        <td>{item.name}</td>
                                        <td>{item.availableQuantity}</td>
                                        <td><Form.Control type="number" placeholder="Input Return Quantity" onChange={(e) => setItemQuantity(e.target.value)} /></td>
                                        <td>{item.itemMeasure}</td>
                                        <td style={{ textAlign: "center" }}>
                                            {!selectedProducts.find((selItem) => selItem.itemId === item.itemId) ?
                                                <Button style={{ fontSize: "0.7rem" }} variant={"info"} onClick={async () => {
                                                    if (itemQuantity === 0) {
                                                        await alert("Enter Item Quantity")
                                                    } else if (selectedProducts.find((selItem) => selItem.itemId === item.itemId)) {
                                                        await alert("item Already Added")
                                                    } else {
                                                        await setSelectedProducts([...selectedProducts, {
                                                            itemId: item.itemId,
                                                            name: item.name,
                                                            availableQuantity: item.availableQuantity,
                                                            itemCategory: item.itemCategory,
                                                            itemGst: Number(item.itemGst),
                                                            itemHsn: item.itemHsn,
                                                            itemMrp: Number(item.itemMrp),
                                                            itemMsp: Number(item.itemMsp),
                                                            purchasePrice: Number(item.purchasePrice),
                                                            selectedQuantity: Number(itemQuantity),
                                                            itemMeasure: item.itemMeasure
                                                        }]);
                                                        await setItemQuantity(0);
                                                    }
                                                }}>Add Item</Button>
                                                :
                                                <span style={{ cursor: "pointer", fontSize: "1.3rem" }} onClick={async () => {
                                                    const itemsRemovedOnClick = await selectedProducts.filter(selectedItem => selectedItem.name !== item.name);
                                                    await setSelectedProducts(itemsRemovedOnClick)
                                                }}>&#10062;</span>
                                            }
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody> :
                            <tbody><tr><td colSpan="6" ><Spinner animation="border" size="sm" /></td></tr></tbody>
                        }
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => handleSelectProductsClose()} >Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WastageSpillage;
