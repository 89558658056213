import React from "react";
import "./barcode-generator.styles.scss";

import { useBarcode } from 'react-barcodes';

export default function BarcodeGenerator({ itemId, itemName, itemMrp, itemMsp }) {
    const { inputRef } = useBarcode({
        value: `${itemId}`,
        options: {
            background: '#FFFFFF',
            format: "CODE128"
        }
    });

    return (
        <div className="barcode-gen-main-wrapper">
            <span className="heading-barcode">Kodagu Trade & Commerce Pvt. Ltd.</span>
            <span className="item-name">{itemName}</span>
            <div className="price-line">
                <div className="item-mrp">MRP : ₹ {Number(itemMrp).toFixed(2)}</div>
                <div className="item-msp">OUR : ₹ {Number(itemMsp).toFixed(2)}</div>
            </div>
            <img className="barcode-svg" ref={inputRef} />
        </div>
    );
}
