import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from '../../../firebase/firebase.utils';
import firebase from 'firebase/app';
import './expense-logger.styles.scss'

const ExpenseLogger = ({ setExpenseLoggedIn }) => {
    // Date Values
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    const [expenseLogs, setExpenseLogs] = useState([]);

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("purchase-expense-logs").orderBy('timeAdded', "desc")
                .where('timeAdded', '>=', start).where('timeAdded', '<=', end);
            docRef.get().then(async (snapshot) => {
                let expenseLogsArray = [];
                await snapshot.docs.map(doc => expenseLogsArray.push(doc.data()))
                await setExpenseLogs(expenseLogsArray)
            })
        };
        fetchLogData();
    }, [value1, value2])

    // Expense Id Generation
    const [expenseId, setExpenseId] = useState('');

    useEffect(() => {
        const onLoadGenRandCode = async () => {
            let randomCode = '';
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (var i = 10; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
            await setExpenseId(randomCode);
        };
        onLoadGenRandCode();
    }, [])

    // Form Values
    const [name, setName] = useState("");
    const [reason, setReason] = useState("");
    const [note, setNote] = useState("NA");
    const [amount, setAmount] = useState(null);
    const [expenseType, setExpenseType] = useState(null);
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    // State and logic to handle user specific logs.
    const [userName, setUserName] = useState("none");

    // Handle Local for logged in user
    useEffect(() => {
        const onLoadUserChanges = async () => {
            if (localStorage.getItem('loggedInUser')) {
                await setUserName(localStorage.getItem('loggedInUser').toLowerCase());
            } else {
                await setUserName("none");
            }
        };
        onLoadUserChanges();
    }, [])

    // Filter Based on the current user
    const filteredLogs = expenseLogs.filter(item => {
        return item.name.toLowerCase().includes(userName.toLowerCase());
    })

    const handleSubmitExpense = async () => {
        if (name.length < 1) {
            await alert("Please Input Your Name")
        } else if (reason.length < 1) {
            await alert("Please Input The Expense Reason")
        } else if (amount === null || amount.length < 1) {
            await alert("Please Input the expense amount")
        } else if (expenseType === null || expenseType.length < 1) {
            await alert("Please Select The Expense Type")
        } else {
            await setLoading(true)
            await localStorage.setItem('loggedInUser', `${name.toLowerCase()}`)
            await firestore.collection("purchase-expense-logs").add({
                expenseId: expenseId,
                name: name,
                reason: reason,
                note: note,
                amount: Number(amount),
                status: "Not Settled",
                expenseType: expenseType,
                timeAdded: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(async () => await setLoading(false)).then(async () => await window.location.reload(false))
        }
    }


    // Handle Logout
    const handleExpenseLogout = () => {
        localStorage.removeItem('expenseLoggedIn');
        return setExpenseLoggedIn(false);
    }

    return (
        <div className="expense-tracker-main-container">
            <div style={{ borderBottom: "1px solid black", paddingBottom: "10px", display: 'flex', justifyContent: "space-between", flexDirection: "column" }}>
                <h4>LOG PURCHASE EXPENSES</h4>
                <div>
                    <a href="/collections">
                        <Button variant="success" style={{ fontSize: "0.8rem", height: "40px" }} >Go To Collection Logger</Button>
                    </a>
                    <Button variant="danger" style={{ fontSize: "0.8rem", height: "40px", marginLeft: "10px" }} onClick={() => handleExpenseLogout()}>Log Out</Button>
                </div>
            </div>
            <div className="expense-log-form">
                <Form.Group controlId="name">
                    <Form.Label><b>1. Expense Logged By</b></Form.Label>
                    <Form.Control type="text" placeholder="Full Name" onChange={(e) => { setName(e.target.value) }} />
                </Form.Group>
                <Form.Group controlId="reason">
                    <Form.Label><b>2. Expense Reason</b></Form.Label>
                    <Form.Control type="text" placeholder="Expense Reason (ex. Auto Fare, Food etc..)" onChange={(e) => setReason(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="note">
                    <Form.Label><b>3. Expense References / Note (If Any)</b></Form.Label>
                    <Form.Control type="text" placeholder="Expense References (If Any)" onChange={(e) => setNote(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="amount">
                    <Form.Label><b>4. Expense Amount In INR</b></Form.Label><br />
                    <Form.Label style={{ color: "red" }}>Select Expense Type</Form.Label>
                    <Form.Control as="select" onChange={(e) => { setExpenseType(e.target.value); console.log(e.target.value) }}>
                        <option></option>
                        <option>purchase</option>
                        <option>misc</option>
                    </Form.Control><br />
                    <Form.Control type="number" placeholder="Expense Amount In INR" onChange={(e) => setAmount(e.target.value)} />
                </Form.Group>
                {loading === false ?
                    <Button variant="info" onClick={() => handleSubmitExpense()}>Submit Expense</Button>
                    : <Button variant="warning" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Processing...</Button>
                }
            </div>
            {/* Show Previous Expense */}
            <div className="show-previous-expense">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 style={{ paddingBottom: "20px", borderBottom: "1px solid gray" }}>Your Previous Expenses</h4>
                </div>
                <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
                <div style={{ display: "flex", paddingTop: "10px" }} className='expense-report-date-range-selectors'>
                    <div style={{ marginRight: "20px", marginBottom: "20px" }}>
                        <span><b>Select From Date : </b></span>
                        <DatePicker className='expense-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <span><b>Select To Date : </b></span>
                        <DatePicker className='expense-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                    </div>
                </div>
                <div className="expense-tracker-report" style={{ borderTop: "1px solid black", paddingTop: "10px", fontSize: "0.8rem" }}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="expense-table-to-xls"
                        filename="expense-report"
                        sheet="tablexls"
                        buttonText="Export as XLS" />
                    <Table striped bordered hover id="expense-table-to-xls" size="sm" responsive="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Expense ID</th>
                                <th>Entry Date</th>
                                <th>Entry Time</th>
                                <th>Name</th>
                                <th>Expense Reason</th>
                                <th>Expense Ref/Note</th>
                                <th>Expense Amount</th>
                            </tr>
                        </thead>
                        {filteredLogs.length > 0 ?
                            <tbody>
                                {filteredLogs.map((item, i) =>
                                    <tr key={i + 1}>
                                        <td>{i + 1}</td>
                                        <td>{item.expenseId}</td>
                                        <td>{`${item.timeAdded.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                        <td>{`${item.timeAdded.toDate().toLocaleTimeString()}`}</td>
                                        <td>{item.name}</td>
                                        <td>{item.reason}</td>
                                        <td>{item.note}</td>
                                        <td>{item.amount.toFixed(2)}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan="8">No Logs Yet</td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                </div>
            </div>
            {/* View all expense button */}
            {/* <div className="expense-view-all-button">
                <a href='/expense/admin'><Button style={{ width: "100%" }} variant="danger">Expense Admin</Button></a>
            </div> */}
        </div>
    )
}

export default ExpenseLogger;
