import React, { useState } from 'react';
import './manage-products.styles.scss';
import { Button, Form, Spinner, Modal } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';

import AddProductModal from './manage-product-modals/add-product-modal.component';
import ProductCard from './product-card/product-card.component';
import BarcodeReader from 'react-barcode-reader';


const ManageProducts = ({ categoryMeta, warehouseProducts, updateWarehouseData, updateCategoryData }) => {
    const [addProductModalShow, setAddProductModalShow] = useState(false);
    // const [editProductModalShow, setEditProductModalShow] = useState(false);
    const [searchProduct, setSearchProduct] = useState("");
    // const [selectedProductId, setSelectedProductId] = useState("");

    // Scan func and states
    const [scanItem, setScanItem] = useState(false);
    const [scanData, setScanData] = useState(null);

    // const filteredProducts = warehouseProducts.filter(item => {
    //     return item.name.toLowerCase().includes(searchProduct.toLowerCase());
    // })

    // Filter Search Products
    const filteredProducts = warehouseProducts.filter(item => {
        if (scanItem === false && scanData === null) {
            return item.name.toLowerCase().includes(searchProduct.toLowerCase()) || item.itemId.toLowerCase().includes(searchProduct.toLowerCase());
        } else if (searchProduct.length > 0) {
            return item.name.toLowerCase().includes(searchProduct.toLowerCase());
        } else {
            return item.itemId.toLowerCase().includes(scanData === null ? "" : scanData.toString().toLowerCase());
        }
    })

    // Handle Delete Product
    const handleDelete = async (itemId) => {
        const docRefDelete = await firestore.collection("warehouse-products-bng");
        docRefDelete.get().then(async (snapshot) => {
            let docId = '';
            await snapshot.docs.map(doc => doc.data().itemId === itemId ? docId = doc.id : null);
            await firestore.collection("warehouse-products-bng").doc(`${docId}`).delete()
                .then(() => alert('Document deleted successfully'))
                .then(() => window.location.reload(false))
        })
    }

    // Scan func and states
    const handleScan = async (data) => {
        await setScanData(null);
        await setScanData(data);
        await setScanItem(false);
    }

    const handleError = async (err) => {
        await alert("Error Occured When Scanning", err)
    }

    const updateWarehouseProductsInner = () => {
        updateWarehouseData()
    }

    // States and funcs to handle gross profit show
    const [showEditDelete, setShowEditDelete] = useState(false);
    const [showEditDeletePasswordModal, setShowEditDeletePasswordModal] = useState(false);
    const [editDeleteProductViewPassword, setEditDeleteProductViewPassword] = useState("")

    const showEditDeleteAfterLogin = async () => {
        let password = "889977";
        if (editDeleteProductViewPassword === password) {
            await setShowEditDelete(true);
            setShowEditDeletePasswordModal(false);
        } else {
            alert("Wrong Pin Entered - Please Try Again")
        }

    }

    return (
        <div className='manage-products-main-container'>
            {scanItem === true ?
                <BarcodeReader
                    onError={handleError}
                    onScan={handleScan}
                // onKeyDetect={handleScan}
                /> : null}
            {/* Show Gross profit P/W Modal */}
            <Modal centered show={showEditDeletePasswordModal} onHide={() => setShowEditDeletePasswordModal(false)}>
                <Modal.Header closeButton>
                    <h4>Edit/Delete Product Control</h4>
                </Modal.Header>
                <Modal.Body>
                    <input placeholder="Enter The 6 Digit Pin" type="password" onChange={(e) => setEditDeleteProductViewPassword(e.target.value)} ></input>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={() => showEditDeleteAfterLogin()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='add-product-block'>
                <h5>Add a New Product</h5>
                <Button variant='info' onClick={() => setAddProductModalShow(true)}>Add New Product</Button>
                <AddProductModal categoryMeta={categoryMeta} show={addProductModalShow} key={21} onHide={() => setAddProductModalShow(false)} updateWarehouseProductsInner={() => updateWarehouseProductsInner()} updateCategoryData={() => updateCategoryData()} />
            </div>
            <div className='search-item'>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label><h4>Search Products</h4></Form.Label>
                    <Form.Control type="search" placeholder="Search Product by Name / Barcode Number" onChange={(event) => setSearchProduct(event.target.value)} />
                </Form.Group>
                {scanItem === false ?
                    <Button variant={"danger"} onClick={() => setScanItem(true)} style={{ fontSize: "0.7rem", marginRight: "20px" }} >Scan Barcode</Button>
                    : <span style={{ marginRight: "20px", color: "red" }}><Spinner animation="grow" variant="secondary" size="sm" /> Scan Barcode Now...
                        <span style={{ color: "orange", cursor: "pointer" }} onClick={() => setScanItem(false)}>  &#10060;</span>
                    </span>
                }
                {!showEditDelete ?
                    <Button style={{ margin: "10px", width: "350px" }} size="sm" variant="warning" onClick={() => setShowEditDeletePasswordModal(true)} >Enable Edit/Delete Product</Button>
                    :
                    <Button style={{ margin: "10px", width: "350px" }} size="sm" variant="danger" onClick={() => { setShowEditDelete(false); setEditDeleteProductViewPassword("") }} >Disable Edit/Delete Product</Button>
                }
            </div>
            {filteredProducts.length > 0 ?
                <div className='product-view'>
                    {filteredProducts.slice(0, 20).map((item, i) =>
                        <ProductCard
                            key={i + 889}
                            name={item.name}
                            itemCategory={item.itemCategory}
                            itemId={item.itemId}
                            purchasePrice={item.purchasePrice}
                            itemMrp={item.itemMrp}
                            itemMsp={item.itemMsp}
                            itemMspB2b={item.itemMspB2b}
                            stockTransferPrice={item.stockTransferPrice ? item.stockTransferPrice : 0}
                            b2bPrice={item.b2bPrice ? item.b2bPrice : 0}
                            homeDeliveryB2c={item.homeDeliveryB2c ? item.homeDeliveryB2c : 0}
                            institutionalB2c={item.institutionalB2c ? item.institutionalB2c : 0}
                            walkInB2c={item.walkInB2c ? item.walkInB2c : 0}
                            availableQuantity={item.availableQuantity}
                            itemMeasure={item.itemMeasure}
                            itemGst={item.itemGst}
                            itemHsn={item.itemHsn}
                            categoryMeta={categoryMeta}
                            showEditDelete={showEditDelete}
                        />
                    )
                    }
                </div> : <div style={{ padding: "30px" }}><Spinner size='sm' animation="grow" variant="info" /><Spinner animation="grow" variant="success" /></div>}
        </div>
    )
}

export default ManageProducts;