import React, { useState } from 'react';
import './expense-page.styles.scss';

import ExpenseLogin from '../components/expense-logger-app/expense-login/expense-login.component';
import ExpenseLogger from '../components/expense-logger-app/expense-logger/expense-logger.component';

const ExpenseMainPage = () => {
    const [expenseLoggedIn, setExpenseLoggedIn] = useState(Boolean(localStorage.getItem('expenseLoggedIn')));

    return (
        <div>
            {expenseLoggedIn === false ?
                <ExpenseLogin setExpenseLoggedIn={(expenseLoggedIn) => setExpenseLoggedIn(expenseLoggedIn)} />
                :
                <ExpenseLogger setExpenseLoggedIn={(expenseLoggedIn) => setExpenseLoggedIn(expenseLoggedIn)} />
            }
        </div>
    )
}

export default ExpenseMainPage;
