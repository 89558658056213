import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';


const EditProductModal = (props) => {
    const [finishedEditing, setFinishedEditing] = useState(true);

    const uploadInfo = {
        name: props.name,
        itemCategory: props.itemCategory,
        purchasePrice: props.purchasePrice,
        itemMrp: props.itemMrp,
        itemMsp: props.itemMsp,
        itemMspB2b: props.itemMspB2b,
        stockTransferPrice: props.stockTransferPrice,
        b2bPrice: props.b2bPrice,
        homeDeliveryB2c: props.homeDeliveryB2c,
        institutionalB2c: props.institutionalB2c,
        walkInB2c: props.walkInB2c,
        itemMeasure: props.itemMeasure,
        availableQuantity: props.availableQuantity,
        itemGst: props.itemGst,
        itemHsn: props.itemHsn,
        itemId: props.itemId,
    };

    const handleEditProduct = async () => {
        await setFinishedEditing(false);
        const docRefDelete = await firestore.collection("warehouse-products");
        docRefDelete.get().then(async (snapshot) => {
            let docId = '';
            await snapshot.docs.map(doc => doc.data().itemId === props.itemId ? docId = doc.id : null);
            await firestore.collection("warehouse-products").doc(`${docId}`)
                .update({
                    name: uploadInfo.name,
                    itemCategory: uploadInfo.itemCategory,
                    purchasePrice: uploadInfo.purchasePrice,
                    itemMrp: uploadInfo.itemMrp,
                    itemMsp: uploadInfo.itemMsp,
                    itemMspB2b: uploadInfo.itemMspB2b,
                    stockTransferPrice: uploadInfo.stockTransferPrice,
                    b2bPrice: uploadInfo.b2bPrice,
                    homeDeliveryB2c: uploadInfo.homeDeliveryB2c,
                    institutionalB2c: uploadInfo.institutionalB2c,
                    walkInB2c: uploadInfo.walkInB2c,
                    itemMeasure: uploadInfo.itemMeasure,
                    availableQuantity: uploadInfo.availableQuantity,
                    itemGst: uploadInfo.itemGst,
                    itemHsn: uploadInfo.itemHsn,
                    itemId: uploadInfo.itemId
                })
                .then(async () => await setFinishedEditing(true))
                .then(async () => await window.location.reload(false))
        })

        // await firestore.collection("warehouse-products").update({
        //     name: uploadInfo.name,
        //     itemCategory: uploadInfo.itemCategory,
        //     purchasePrice: uploadInfo.purchasePrice,
        //     itemMrp: uploadInfo.itemMrp,
        //     itemMsp: uploadInfo.itemMsp,
        //     itemMeasure: uploadInfo.itemMeasure,
        //     availableQuantity: uploadInfo.availableQuantity,
        //     itemGst: uploadInfo.itemGst,
        //     itemHsn: uploadInfo.itemHsn,
        //     itemId: uploadInfo.itemId
        // }).where("itemId", "==", props.itemId)
    }

    return (
        <Modal  {...props} size="xl" centered >
            <Modal.Header closeButton>
                <Modal.Title>Edit Product Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control type="text" placeholder="Product Name" defaultValue={`${props.name}`} onChange={(event) => uploadInfo.name = event.target.value} />
                </Form.Group>
                <div style={{ display: 'flex' }} className='add-product-form-block-1'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Purchase Price</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="Purchase Price" defaultValue={`${props.purchasePrice}`} onChange={(event) => uploadInfo.purchasePrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MRP</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MRP" defaultValue={`${props.itemMrp}`} onChange={(event) => uploadInfo.itemMrp = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MSP</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MSP" defaultValue={`${props.itemMsp}`} onChange={(event) => uploadInfo.itemMsp = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MSP B2B</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MSP" defaultValue={`${props.itemMspB2b}`} onChange={(event) => uploadInfo.itemMspB2b = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Available Quantity</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="Qty." defaultValue={`${props.availableQuantity}`} onChange={(event) => uploadInfo.availableQuantity = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item Measure</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '250px' }} as="select" defaultValue={`${props.itemMeasure}`} onChange={(event) => uploadInfo.itemMeasure = event.target.value}>
                                <option selected>{null}</option>
                                <option>Kilograms</option>
                                <option>Liters</option>
                                <option>Numbers</option>
                            </FormControl>
                        </InputGroup>
                    </div>
                </div>
                <div style={{ display: 'flex' }} className='add-product-form-block-2'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item GST</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '250px' }} type='number' placeholder="Item GST" defaultValue={`${props.itemGst}`} onChange={(event) => uploadInfo.itemGst = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item HSN</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text ></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '250px' }} type='text' placeholder="Item HSN" defaultValue={`${props.itemHsn}`} onChange={(event) => uploadInfo.itemHsn = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item Category</Form.Label>
                        <InputGroup className="mb-3">

                            <FormControl style={{ width: '350px' }} as="select" onChange={(event) => uploadInfo.itemCategory = event.target.value}>
                                <option selected>{props.itemCategory}</option>
                                {props.categoryMeta.length > 0 ?
                                    <>
                                        {
                                            props.categoryMeta.map((item, i) =>
                                                <option>{item.category}</option>
                                            )
                                        }
                                    </> : <option>Loading...</option>}
                            </FormControl>
                        </InputGroup>
                    </div>
                </div>
                {/* Different Prices */}
                <div style={{ display: 'flex' }} className='add-product-form-block-2'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Stock Transfer Price</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Stock Transfer Price" defaultValue={`${props.stockTransferPrice}`} onChange={(event) => uploadInfo.stockTransferPrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Price For B2B</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='text' placeholder="Price For B2B" defaultValue={`${props.b2bPrice}`} onChange={(event) => uploadInfo.b2bPrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Home Delivery</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='text' placeholder="Home Delivery" defaultValue={`${props.homeDeliveryB2c}`} onChange={(event) => uploadInfo.homeDeliveryB2c = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Institutional</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='text' placeholder="Institutional" defaultValue={`${props.institutionalB2c}`} onChange={(event) => uploadInfo.institutionalB2c = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Walk In</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='text' placeholder="Walk In" defaultValue={`${props.walkInB2c}`} onChange={(event) => uploadInfo.walkInB2c = event.target.value} />
                        </InputGroup>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {finishedEditing === true ?
                    <Button variant="success" onClick={() => handleEditProduct()}> Save Changes </Button>
                    :
                    <Button variant="success" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditProductModal;