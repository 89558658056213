import React, { useEffect, useState } from 'react';
import './main-header.styles.scss';
import { Navbar, Nav, NavDropdown, Button, Form } from 'react-bootstrap';


const MainHeader = ({ setIsLoggedIn, setCurrentComponent }) => {

    // handle logout
    const handleLogout = () => {
        localStorage.removeItem("warehouseLoggedInBng");
        return setIsLoggedIn(false);
    }

    return (
        <div className='wh-admin-header-container'>
            <Navbar className='wh-admin-navbar-container' collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/admin"><img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' height='50px' alt='wh-admin-logo'></img> | WAREHOUSE ADMIN</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav style={{ fontSize: "0.8rem" }} className="mr-auto">
                        <Nav.Link href="#action/1" onClick={() => { setCurrentComponent('manageProducts'); localStorage.setItem('currentAdminComponentLocal', 'manageProducts') }}>Manage Products</Nav.Link>
                        <Nav.Link href="#action/2" onClick={() => { setCurrentComponent('listWarehouseItems'); localStorage.setItem('currentAdminComponentLocal', 'listWarehouseItems') }}> Product Report</Nav.Link>
                        <Nav.Link href="#action/3" onClick={() => { setCurrentComponent('purchaseStock'); localStorage.setItem('currentAdminComponentLocal', 'purchaseStock') }}> Stock Transfer-In</Nav.Link>
                        <Nav.Link href="#action/13" onClick={() => { setCurrentComponent('purchaseBills'); localStorage.setItem('currentAdminComponentLocal', 'purchaseBills') }}> Upload/View Purchase Bills</Nav.Link>
                        <Nav.Link href="#action/4" onClick={() => { setCurrentComponent('purchaseReport'); localStorage.setItem('currentAdminComponentLocal', 'purchaseReport') }}> Transfer-In Report</Nav.Link>
                        <Nav.Link href="#action/5" onClick={() => { setCurrentComponent('stockTransfer'); localStorage.setItem('currentAdminComponentLocal', 'stockTransfer') }}> Stock Transfer-Out</Nav.Link>
                        <Nav.Link href="#action/6" onClick={() => { setCurrentComponent('transferReport'); localStorage.setItem('currentAdminComponentLocal', 'transferReport') }}> Transfer-Out Report</Nav.Link>
                        <Nav.Link href="#action/7" onClick={() => { setCurrentComponent('indentReport'); localStorage.setItem('currentAdminComponentLocal', 'indentReport') }}> Indent Report</Nav.Link>
                        <Nav.Link href="#action/8" onClick={() => { setCurrentComponent('returnProducts'); localStorage.setItem('currentAdminComponentLocal', 'returnProducts') }}> Return Stock</Nav.Link>
                        <Nav.Link href="#action/9" onClick={() => { setCurrentComponent('returnReport'); localStorage.setItem('currentAdminComponentLocal', 'returnReport') }}> Return Report</Nav.Link>
                        <Nav.Link href="#action/10" onClick={() => { setCurrentComponent('wastageSpillage'); localStorage.setItem('currentAdminComponentLocal', 'wastageSpillage') }}> Add wastage</Nav.Link>
                        <Nav.Link href="#action/11" onClick={() => { setCurrentComponent('wastageReport'); localStorage.setItem('currentAdminComponentLocal', 'wastageReport') }}> Wastage Report</Nav.Link>
                        <Nav.Link href="#action/12" onClick={() => { setCurrentComponent('productsReturnedToWarehouse'); localStorage.setItem('currentAdminComponentLocal', 'productsReturnedToWarehouse') }}> Returns To Warehouse</Nav.Link>

                    </Nav>
                    <Form inline>
                        <Button variant="outline-danger" onClick={() => handleLogout()}>Logout</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default MainHeader;