import React, { useState, useEffect, useRef } from 'react';
import './return-report.styles.scss';
import { Form, Spinner, Table, Button, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from '../../firebase/firebase.utils';
import firebase from 'firebase/app';
import CatLogo from '../../res/cat-store-main-logo.png';

import { useReactToPrint } from 'react-to-print';

const ReturnReport = ({ storeDetails }) => {
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    // State to store the warehouse logs
    const [returnLogs, setReturnLogs] = useState([]);
    // State to save store name
    const [storeName, setStoreName] = useState("all");
    // state to save return id for modal
    const [returnId, setReturnId] = useState('');
    const [selectedLog, setSelectedLog] = useState(null);

    // Modal States and functions
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Component Ref to print and print function
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("warehouse-logs-return-stock").orderBy('timeUpdated', "desc")
                .where('timeUpdated', '>=', start).where('timeUpdated', '<=', end);
            docRef.get().then(async (snapshot) => {
                let returnLogsArray = [];
                await snapshot.docs.map(doc => returnLogsArray.push(doc.data()))
                await setReturnLogs(returnLogsArray)
            })
        };
        fetchLogData();
    }, [value1, value2])

    const filteredLogs = returnLogs.filter(item => {
        if (storeName === "all") {
            return item.toStore.toLowerCase().includes(" ");
        } else {
            return item.toStore.toLowerCase().includes(storeName.toLowerCase());
        }
    })

    // Effect to modal data for debit view
    useEffect(() => {
        const fetchLogData = async () => {
            const docRef = await firestore.collection("warehouse-logs-return-stock").where('returnId', '==', `${returnId}`)
            docRef.get().then(async (snapshot) => {
                let returnData = [];
                await snapshot.docs.map(doc => returnData.push(doc.data()))
                await setSelectedLog(returnData[0])
                await console.log(returnData)
            })
        };
        fetchLogData();
    }, [returnId])

    return (
        <div className='return-report-main-container'>
            <h3>Return Report</h3>
            <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
            <div className='return-report-date-range-selectors'>
                <div>
                    <span><b>Select From Date : </b></span>
                    <DatePicker className='return-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div>
                    <span><b>Select To Date : </b></span>
                    <DatePicker className='return-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div style={{ display: "flex" }}>
                    <span><b>Returned To Store : </b></span>
                    <Form.Control style={{ marginLeft: "20px", width: "400px" }} as="select" onChange={(e) => setStoreName(e.target.value.toLowerCase())} >
                        <option selected>all</option>
                        <option>internal transfer</option>
                        {storeDetails.map((item, i) =>
                            <option> {item.storeName.toLowerCase()}</option>
                        )}
                    </Form.Control>
                </div>
            </div>
            <div className="return-report-table-view">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="return-table-to-xls"
                    filename="return-report"
                    sheet="tablexls"
                    buttonText="Export as XLS" />
                <Table striped bordered hover size="sm" id="return-table-to-xls" style={{ fontSize: "0.7rem" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Return ID</th>
                            <th>Date Of Return</th>
                            <th>Returned To</th>
                            <th>Returned By</th>
                            <th>Reason</th>
                            <th>Returned Products</th>
                            <th>View Debit Note</th>
                        </tr>
                    </thead>
                    {returnLogs.length > 0 ?
                        <>
                            {filteredLogs.length !== 0 ?
                                <tbody>
                                    {filteredLogs.map((item, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.returnId}</td>
                                            <td>{`${item.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.toStore}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.addedBy}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.returnReason.toString().replace('{', '').replace('}', '')}</td>
                                            <td>
                                                <Table striped bordered hover size="sm" style={{ fontSize: "0.8rem" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Returned Quantity</th>
                                                            <th>Purchase Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.productsUpdated.map((pro, i) =>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{pro.itemId}</td>
                                                                <td>{pro.name}</td>
                                                                <td>{pro.selectedQuantity} {pro.itemMeasure}</td>
                                                                <td>{pro.purchasePrice.toFixed(2)} INR</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td colSpan="3"></td>
                                                            <td style={{ color: "blue" }}><b>Total</b></td>
                                                            <td style={{ color: "blue" }}><b>{item.productsUpdated.reduce((accumalatedQuantity, product) => accumalatedQuantity + Number(product.purchasePrice * product.selectedQuantity), 0).toFixed(2)} INR</b></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                            <td><Button variant="info" style={{ fontSize: "0.6rem" }} onClick={async () => { await setReturnId(item.returnId); await handleShow() }}>View Debit Note</Button></td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="8">No Logs Found</td>
                            }
                        </>
                        :
                        <td colSpan="8"><Spinner variant="info" size="sm" animation="border" /></td>
                    }
                </Table>
            </div>
            {/* Debit Note Modal */}
            <Modal show={show} onHide={() => handleClose()} centered size="xl">
                <Modal.Header closeButton>
                    <h5>DEBIT NOTE</h5>
                    <Button onClick={() => handlePrint()} style={{ marginLeft: "75%", fontSize: "0.9rem" }}>Print Debit Note</Button>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {selectedLog ?
                        <div className="debit-note-main-container" ref={componentRef}>
                            <div className='header' style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h3 style={{ padding: "15px" }}>DEBIT NOTE</h3>
                                <h6>RETURN #: {`${selectedLog.returnId}`}</h6>
                            </div>
                            <div className="details-section">
                                <div><img src={CatLogo} width="60px"></img></div>
                                <div>Kodagu Trade And Commerce Pvt. Ltd.</div>
                                <div className="right-address-section">
                                    <span><b>Date: {`${selectedLog.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</b></span>
                                    <span>GSTIN: 29AAICK2158E1ZH</span>
                                    <span>Phone: +91-6366-922-199 | +91-6366-922-190</span>
                                    <span>Email: Contact@Catkodagu.Com</span>
                                    <span>Address: No 1, Shri Shambavananda Complex, Malbar Road, Virajpet - 571219</span>
                                </div>
                            </div>
                            <div style={{ width: "98%", marginTop: "20px", borderBottom: "1px solid black" }} className="product-view">
                                <div style={{ padding: "10px", border: "1px solid grey", marginBottom: "20px" }}>
                                    <h6 style={{ textTransform: "capitalize" }}>Returned To : {`${selectedLog.toStore}`}</h6>
                                    <h6 style={{ textTransform: "capitalize" }}>Reason For Return : {`${selectedLog.returnReason}`}</h6>
                                </div>
                                <div style={{ width: "100%", fontSize: "0.8rem" }} className='left-table'>
                                    <Table bordered size="sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Unit Purchase Price</th>
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedLog.productsUpdated.map((item, i) =>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.selectedQuantity} {item.itemMeasure}</td>
                                                    <td>₹ {item.purchasePrice.toFixed(2)}</td>
                                                    <td>₹ {(Number(item.purchasePrice) * Number(item.selectedQuantity)).toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan="3"></td>
                                                <td style={{ backgroundColor: "lightblue" }}><b>Grand Total</b></td>
                                                <td style={{ backgroundColor: "lightblue" }}>₹ <b>{selectedLog.productsUpdated.reduce((accumalatedQuantity, product) => accumalatedQuantity + (Number(product.purchasePrice) * Number(product.selectedQuantity)), 0).toFixed(2)}</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        :
                        <p>Loading...</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReturnReport;
