import './upload-view-purchase-bills.styles.scss';
import React, { useState, useEffect, Component } from 'react';
import { Form, Button, ProgressBar, Table, Spinner, Modal } from 'react-bootstrap';
import firebase from 'firebase/app';
import { firestore, imgStorage } from '../../firebase/firebase.utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const UploadViewPurchaseBills = () => {
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());
    const [imageFullName, setImageFullName] = useState('');
    const [imageRefUrl, setImageRefUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileIdGen, setFileIdGen] = useState('');
    const [purchaseStore, setPurchaseStore] = useState('');
    const [uploadedBy, setUploadedBy] = useState('');

    // State to store the file logs
    const [fileLogs, setFileLogs] = useState([]);

    // modal controls
    const [show, setShow] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("warehouse-purchase-bill-metadata").orderBy('timeAdded', "desc")
                .where('timeAdded', '>=', start).where('timeAdded', '<=', end);
            docRef.get().then(async (snapshot) => {
                let fileLogsArray = [];
                await snapshot.docs.map(doc => fileLogsArray.push(doc.data()))
                console.log(fileLogsArray)
                await setFileLogs(fileLogsArray)
            })
        };
        fetchLogData();
    }, [value1, value2])

    // Handle Image Upload

    let imageName = null;
    let imageFile = null;

    // Effect to create random code
    useEffect(() => {
        const onLoadGenRandCode = async () => {
            let randomCode = '';
            const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (var i = 18; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
            await setFileIdGen(randomCode);
        };
        onLoadGenRandCode();
    }, [])

    // Handle Submit file details
    const uploadInfo = {
        fileId: `${fileIdGen}`,
        fileUrl: `${imageRefUrl}`,
        fileName: `${imageFullName}`,
        uploadBy: `${uploadedBy}`,
        purchaseStore: `${purchaseStore}`,
        timeAdded: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const handleUpload = async () => {
        if (imageFile === null) {
            alert("No File Selected")
        }
        imgStorage.child(`purchase-bills/${imageName}`).getDownloadURL().then(onResolve, onReject);
        function onResolve(foundURL) {
            alert("A file with the same name exists, please rename the file and try again")
        }
        function onReject(error) {
            let uploadTask = imgStorage.child(`purchase-bills/${imageName}`).put(imageFile);
            setImageFullName(`${imageName}`);
            uploadTask.on('state_changed', function (snapshot) {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                alert("Upload Failed, please refresh the page and try again")
            }, function () {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    setImageRefUrl(`${downloadURL}`);
                });
            });
        }
    };

    //  Handle Submit Article
    const handleSubmitArticle = async () => {
        if (purchaseStore === null || purchaseStore.length < 1) {
            await alert("Please input the store name")
        } else if (imageRefUrl === null || imageRefUrl.length < 1) {
            await alert("Please add a Image / you forgot to upload the image after selecting")
        } else if (uploadedBy === null || uploadedBy.length < 1) {
            await alert("Please input the handler/uploader name")
        } else {
            await firestore.collection("warehouse-purchase-bill-metadata").add(uploadInfo).then(async () => await window.location.reload(false));
        }
    }


    return (
        <div className="upload-view-bills-main-container">
            <div className="upload-bills-container">
                <h4>UPLOAD FILE</h4><br />
                <div className='add-file-wrapper'>
                    <h6>1. Upload File</h6>
                    <Form.File className='add-article-image-upload-field' id="exampleFormControlFile1" onChange={(event) => { imageName = event.target.files[0].name; imageFile = event.target.files[0] }} />
                    <ProgressBar animated variant="info" className='upload-image-progress' now={uploadProgress} />
                    {uploadProgress === 100 ? <><br /><p style={{ color: 'green' }} >File Uploaded</p></> : null}
                    <Button className='upload-image-button' variant={'primary'} onClick={() => handleUpload()} >Upload File</Button>
                </div>
                <div className='add-extra-details-wrapper'>
                    <h6>2. Purchased From</h6>
                    <Form.Control className='add-storename-form-field' type="text" placeholder="Purchased From" onChange={(event) => setPurchaseStore(event.target.value)} /><br />
                    <h6>3. Uploaded By</h6>
                    <Form.Control className='add-by-form-field' type="text" placeholder="Uploaded By" onChange={(event) => setUploadedBy(event.target.value)} /><br />
                    <Button className='admin-submit-file-button' variant={'success'} onClick={() => handleSubmitArticle()} >SUBMIT FILE</Button>
                </div>
            </div>
            <div className="view-bills-container">
                <div className='view-bills-main-container'>
                    <h3>View Uploaded Bills</h3>
                    <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
                    <div className='view-bills-date-range-selectors'>
                        <div>
                            <span><b>Select From Date : </b></span>
                            <DatePicker className='view-bills-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                        </div>
                        <div>
                            <span><b>Select To Date : </b></span>
                            <DatePicker className='view-bills-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                        </div>
                    </div>
                    <div className="view-bills-table-view">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="view-bills-to-xls"
                            filename="view-bills"
                            sheet="tablexls"
                            buttonText="Export to XLS" />
                        <Table striped bordered hover size="sm" id="view-bills-to-xls" style={{ fontSize: "0.9rem" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date Uploaded</th>
                                    <th>File ID</th>
                                    <th>Uploaded By</th>
                                    <th>From Store</th>
                                    <th>File Name</th>
                                    <th>View / Download File</th>
                                </tr>
                            </thead>
                            {fileLogs.length > 0 ?
                                <>
                                    {fileLogs.length !== 0 ?
                                        <tbody>
                                            {fileLogs.map((item, i) =>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{`${item.timeAdded.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                                    <td>{item.fileId}</td>
                                                    <td>{item.uploadBy}</td>
                                                    <td>{item.purchaseStore}</td>
                                                    <td>{item.fileName}</td>
                                                    <td>{<Button size="sm" variant="secondary" onClick={() => { handleShow(); setPreviewUrl(`${item.fileUrl}`) }}>View/Download</Button>}</td>
                                                    {/* <td><embed src={`${item.fileUrl}`} width="200px" height="600px" /></td> */}
                                                </tr>
                                            )}
                                        </tbody>
                                        :
                                        <td colSpan="6">No Logs Found</td>
                                    }
                                </>
                                :
                                <td colSpan="6"><Spinner variant="info" size="sm" animation="border" /></td>
                            }
                        </Table>
                    </div>
                </div>
            </div>
            {/* View Modal */}
            <Modal centered size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h4>File Preview</h4>
                </Modal.Header>
                <Modal.Body>
                    {previewUrl.length > 0 ?
                        <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <a target="_blank" href={`${previewUrl}`}><Button size="sm" variant="info" >View / Download File</Button></a><br />
                            <embed src={`${previewUrl}`} width="350px" height="600px" />
                        </div> : "No File To Preview"}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UploadViewPurchaseBills;
