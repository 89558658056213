import React, { useEffect, useState } from 'react';
import './main-component-view.styles.scss';

import ProductReport from '../product-report/product-report.component';
import ManageProducts from '../manage-products/manage-products.component';
import PurchaseStock from '../purchase-stock/purchase-stock.component';
import PurchaseReport from '../purchase-report/purchase-report.component';
import TransferOut from '../transfer-out/transfer-out.component';
import TransferOutReport from '../transfer-out-report/transfer-out-report.component';
import IndentReport from '../indent-report/indent-report.component';
import ReturnProducts from '../return-products/return-products.component';
import ReturnReport from '../return-report/return-report.component';
import WastageSpillage from '../wastage-spillage/wastage-spillage.component';
import WastageReport from '../wastage-report/wastage-report.component';
import ProductsReturnedToWarehouse from '../warehouse-returned-products-report/warehouse-returned-products-report.component';
import UploadViewPurchaseBills from '../upload-view-purchase-bills/upload-view-purchase-bills.component';
import { firestore } from '../../../firebase/firebase.utils';


const MainComponentView = ({ currentComponent }) => {
    const [categoryMeta, setcategoryMeta] = useState([]);
    // const [shopData, setShopData] = useState([]);
    // const [prodNames, setProdNames] = useState([]);
    const [warehouseProducts, setWarehouseProducts] = useState([]);
    const [storeDetails, setStoreDetails] = useState([]);
    const [stockHandlers, setStockHandlers] = useState([]);
    const [indentLogs, setIndentLogs] = useState([]);

    // Effect to fetch all shop data
    // useEffect(() => {
    //     const fetchShopData = async () => {
    //         const docRef = await firestore.collection("stores").doc("coorg-basket").collection("categories");
    //         docRef.get().then((snapshot) => {
    //             let data = []
    //             snapshot.docs.map(doc =>
    //                 data.push(doc.data().items[0]))
    //             setShopData(data)
    //         })
    //     };
    //     fetchShopData();
    // }, [])

    // Effect to fetch all warehouse product data
    useEffect(() => {
        const fetchWarehouseData = async () => {
            const docRef = await firestore.collection("warehouse-products-bng");
            docRef.get().then((snapshot) => {
                let whData = [];
                snapshot.docs.map(doc =>
                    whData.push(doc.data()))
                setWarehouseProducts(whData)
            })
        };
        fetchWarehouseData();
    }, [])

    // Function to update new product data
    const updateWarehouseData = async () => {
        const docRef = await firestore.collection("warehouse-products-bng");
        docRef.get().then((snapshot) => {
            let whData = [];
            snapshot.docs.map(doc =>
                whData.push(doc.data()))
            setWarehouseProducts(whData)
        })
    }

    // Effect to fetch handler data
    useEffect(() => {
        const fetchHandlerData = async () => {
            const docRef = await firestore.collection("stock-handlers");
            docRef.get().then((snapshot) => {
                let handlerData = [];
                snapshot.docs.map(doc =>
                    handlerData.push(doc.data()))
                setStockHandlers(handlerData)
            })
        };
        fetchHandlerData();
    }, [])

    // Function to update new handler data
    const updateHandlerData = async () => {
        const docRef = await firestore.collection("stock-handlers");
        docRef.get().then((snapshot) => {
            let handlerData = [];
            snapshot.docs.map(doc =>
                handlerData.push(doc.data()))
            setStockHandlers(handlerData)
        })
    }


    // Effect to fetch category and sub category meta data
    useEffect(() => {
        const fetchCategoryData = async () => {
            const categoryRef = await firestore.collection("stores").doc("coorg-basket").collection("category-meta-data");
            categoryRef.get().then((snapshot) => {
                let catData = [];
                snapshot.docs.map(async doc => { await catData.push(doc.data()); await setcategoryMeta(catData) })
            });
        };
        fetchCategoryData();
    }, [])

    // Function to update new handler data
    const updateCategoryData = async () => {
        const categoryRef = await firestore.collection("stores").doc("coorg-basket").collection("category-meta-data");
        categoryRef.get().then((snapshot) => {
            let catData = [];
            snapshot.docs.map(async doc => { await catData.push(doc.data()); await setcategoryMeta(catData) })
        });
    }

    // Fetch Store Details
    useEffect(() => {
        const fetchStoreDetails = async () => {
            const docRef = await firestore.collection("store-details");
            docRef.get().then((snapshot) => {
                let details = []
                snapshot.docs.map(doc =>
                    details.push(doc.data()))
                setStoreDetails(details)
            })
        };
        fetchStoreDetails();
    }, [setStoreDetails])

    // Function to update Store Details
    const updateStoreDetails = async () => {
        const docRef = await firestore.collection("store-details");
        docRef.get().then((snapshot) => {
            let details = []
            snapshot.docs.map(doc =>
                details.push(doc.data()))
            setStoreDetails(details)
        })
    }

    // Effect to fetch indent data
    useEffect(() => {
        const fetchLogData = async () => {
            const docRef = await firestore.collection("indent-requests-bng").orderBy('timeOfIndent', "desc")
            docRef.get().then(async (snapshot) => {
                let indentLogsArray = [];
                await snapshot.docs.map(doc => indentLogsArray.push(doc.data()))
                await setIndentLogs(indentLogsArray)
            })
        };
        fetchLogData();
    }, [])

    return (
        <div className='wh-component-view-container'>
            {currentComponent === 'listWarehouseItems' ? <ProductReport warehouseProducts={warehouseProducts} /> : null}
            {currentComponent === 'manageProducts' ? <ManageProducts categoryMeta={categoryMeta} warehouseProducts={warehouseProducts} updateWarehouseData={() => updateWarehouseData()} key={32} updateCategoryData={() => updateCategoryData()} /> : null}
            {currentComponent === 'purchaseStock' ? <PurchaseStock categoryMeta={categoryMeta} warehouseProducts={warehouseProducts} stockHandlers={stockHandlers} storeDetails={storeDetails} updateWarehouseData={() => updateWarehouseData()} updateHandlerData={() => updateHandlerData()} updateCategoryData={() => updateCategoryData()} updateStoreDetails={() => updateStoreDetails()} /> : null}
            {currentComponent === 'purchaseReport' ? <PurchaseReport storeDetails={storeDetails} /> : null}
            {currentComponent === 'stockTransfer' ? <TransferOut categoryMeta={categoryMeta} warehouseProducts={warehouseProducts} stockHandlers={stockHandlers} storeDetails={storeDetails} updateWarehouseData={() => updateWarehouseData()} updateHandlerData={() => updateHandlerData()} updateCategoryData={() => updateCategoryData()} updateStoreDetails={() => updateStoreDetails()} /> : null}
            {currentComponent === 'transferReport' ? <TransferOutReport storeDetails={storeDetails} /> : null}
            {currentComponent === 'indentReport' ? <IndentReport storeDetails={storeDetails} indentLogs={indentLogs} /> : null}
            {currentComponent === 'returnProducts' ? <ReturnProducts categoryMeta={categoryMeta} warehouseProducts={warehouseProducts} stockHandlers={stockHandlers} storeDetails={storeDetails} updateWarehouseData={() => updateWarehouseData()} updateHandlerData={() => updateHandlerData()} updateCategoryData={() => updateCategoryData()} updateStoreDetails={() => updateStoreDetails()} /> : null}
            {currentComponent === 'returnReport' ? <ReturnReport storeDetails={storeDetails} /> : null}
            {currentComponent === 'wastageSpillage' ? <WastageSpillage categoryMeta={categoryMeta} warehouseProducts={warehouseProducts} stockHandlers={stockHandlers} storeDetails={storeDetails} updateWarehouseData={() => updateWarehouseData()} updateHandlerData={() => updateHandlerData()} updateCategoryData={() => updateCategoryData()} updateStoreDetails={() => updateStoreDetails()} /> : null}
            {currentComponent === 'wastageReport' ? <WastageReport /> : null}
            {currentComponent === 'productsReturnedToWarehouse' ? <ProductsReturnedToWarehouse storeDetails={storeDetails} /> : null}
            {currentComponent === 'purchaseBills' ? <UploadViewPurchaseBills /> : null}
        </div>
    )
}

export default MainComponentView;