import React, { useState, useEffect } from 'react';
import './purchase-report.styles.scss';
import { Form, Spinner, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from '../../../firebase/firebase.utils';
import firebase from 'firebase/app';

const PurchaseReport = ({ storeDetails }) => {
    var date = new Date();
    // remove a day
    date.setDate(date.getDate() - 8);
    date.setUTCHours(18);
    date.setUTCMinutes(30)
    const [value1, onChange1] = useState(date);
    const [value2, onChange2] = useState(new Date());

    // State to store the warehouse logs
    const [warehouseLogs, setWarehouseLogs] = useState([]);
    // State to save store name
    const [storeName, setStoreName] = useState("all");

    // Effect to fetch data
    useEffect(() => {
        const fetchLogData = async () => {
            let start = firebase.firestore.Timestamp.fromDate(value1);
            let end = firebase.firestore.Timestamp.fromDate(value2);
            const docRef = await firestore.collection("warehouse-logs-bng").orderBy('timeUpdated', "desc")
                .where('timeUpdated', '>=', start).where('timeUpdated', '<=', end);
            docRef.get().then(async (snapshot) => {
                let warehouseLogsArray = [];
                await snapshot.docs.map(doc => warehouseLogsArray.push(doc.data()))
                await setWarehouseLogs(warehouseLogsArray)
            })
        };
        fetchLogData();
    }, [value1, value2])

    const filteredLogs = warehouseLogs.filter(item => {
        if (storeName === "all") {
            return item.fromStore.toLowerCase().includes("");
        } else {
            return item.fromStore.toLowerCase().includes(storeName.toLowerCase());
        }
    })


    return (
        <div className='purchase-report-main-container'>
            <h3>Transfer-In Report</h3>
            <span style={{ color: "red" }}>Note: If Date Range is not selected the Range defaults to last 7 days data</span>
            <div className='purchase-report-date-range-selectors'>
                <div>
                    <span><b>Select From Date : </b></span>
                    <DatePicker className='purchase-report-date-picker' showTimeSelect selected={value1} onChange={date => onChange1(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div>
                    <span><b>Select To Date : </b></span>
                    <DatePicker className='purchase-report-date-picker' showTimeSelect selected={value2} onChange={date => onChange2(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                </div>
                <div style={{ display: "flex" }}>
                    <span><b>Sourced From Store : </b></span>
                    <Form.Control style={{ marginLeft: "20px", width: "400px" }} as="select" onChange={(e) => setStoreName(e.target.value.toLowerCase())} >
                        <option selected>all</option>
                        <option>internal transfer</option>
                        {storeDetails.map((item, i) =>
                            <option> {item.storeName.toLowerCase()}</option>
                        )}
                    </Form.Control>
                </div>
            </div>
            <div className="purchase-report-table-view">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="purchase-table-to-xls"
                    filename="transfer-in-report"
                    sheet="tablexls"
                    buttonText="Export to XLS" />
                <Table striped bordered hover size="sm" id="purchase-table-to-xls" style={{ fontSize: "0.9rem" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Transfer-In ID</th>
                            <th>Time Of Entry</th>
                            <th>Sourced From</th>
                            <th>Purchase Inv. #</th>
                            <th>Sourced By</th>
                            <th>Sourced Products</th>
                        </tr>
                    </thead>
                    {warehouseLogs.length > 0 ?
                        <>
                            {filteredLogs.length !== 0 ?
                                <tbody>
                                    {filteredLogs.map((item, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.transferInId}</td>
                                            <td>{`${item.timeUpdated.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.fromStore}</td>
                                            <td>{item.purchaseInvoiceNumber ? item.purchaseInvoiceNumber : "NA"}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.addedBy}</td>
                                            <td>
                                                <Table striped bordered hover size="sm" style={{ fontSize: "0.8rem" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Product Quantity</th>
                                                            <th>Measure</th>
                                                            <th>MRP in ₹</th>
                                                            <th>Purchase Price</th>
                                                            <th>PP x Qty</th>
                                                            <th>% GST</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.productsUpdated.map((pro, i) =>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{pro.itemId}</td>
                                                                <td>{pro.name}</td>
                                                                <td>{pro.selectedQuantity}</td>
                                                                <td>{pro.itemMeasure}</td>
                                                                <td>{Number(pro.itemMrp).toFixed(2)}</td>
                                                                <td>{pro.purchasePrice ? pro.purchasePrice : "0"}</td>
                                                                <td>{(Number(pro.purchasePrice) * Number(pro.selectedQuantity)).toFixed(2)}</td>
                                                                <td>{pro.itemGst}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <td colSpan="6">No Logs Found</td>
                            }
                        </>
                        :
                        <td colSpan="6"><Spinner variant="info" size="sm" animation="border" /></td>
                    }
                </Table>
            </div>
        </div>
    )
}

export default PurchaseReport;