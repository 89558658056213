import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';
import BarcodeReader from 'react-barcode-reader';

const AddProductModal = (props) => {
    const [productCode, setProductCode] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [finishedAdding, setFinishedAdding] = useState(true);
    const [showCat, setShowCat] = useState(false);
    const [scanItem, setScanItem] = useState(false);

    const [scanData, setScanData] = useState(null);

    const handleCloseCat = () => setShowCat(false);
    const handleShowCat = () => setShowCat(true);

    // Effect to create random code
    useEffect(() => {
        const onLoadGenRandCode = async () => {
            let randomCode = '';
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (var i = 14; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
            await setProductCode(randomCode);
        };
        onLoadGenRandCode();
    }, [])

    // random code copy on new products
    const onLoadRegenerateCode = async () => {
        let randomCode = '';
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 14; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
        await setProductCode(randomCode);
    }

    const uploadInfo = {
        name: null,
        itemCategory: null,
        purchasePrice: 0,
        itemMrp: 0,
        itemMsp: 0,
        itemMspB2b: 0,
        stockTransferPrice: 0,
        b2bPrice: 0,
        homeDeliveryB2c: 0,
        institutionalB2c: 0,
        walkInB2c: 0,
        itemMeasure: null,
        availableQuantity: 0,
        itemGst: null,
        itemHsn: "",
        itemId: `${scanData !== null ? scanData.toString() : `CAT-${productCode}`}`,
        // itemId: `CAT-${productCode}`,
    };

    // Handle Submit Product to inventory
    const handleSubmitProduct = async () => {
        await setFinishedAdding(false)
        // get product ids
        let productIdArray = [];
        const proIdRef = await firestore.collection("warehouse-products");
        await proIdRef.get()
            .then(querySnapshot => {
                querySnapshot.docs.map(doc => productIdArray.push(doc.data().itemId))
            })

        const docId = [];
        if (uploadInfo.name === null) {
            alert("Please Add The Product Name")
        } else if (Number(uploadInfo.purchasePrice) === 0 || Number(uploadInfo.purchasePrice) < 0) {
            alert("Please Add the Purchase Price / The purchase price can not be 0")
        } else if (Number(uploadInfo.itemMrp) === 0 || Number(uploadInfo.itemMrp) < 0) {
            alert("Please Add the MRP / The MRP price can not be 0")
        } else if (Number(uploadInfo.itemMsp) === 0 || Number(uploadInfo.itemMsp) < 0) {
            alert("Please Add the MSP / The MSP price can not be 0")
        } else if (Number(uploadInfo.itemMspB2b) === 0 || Number(uploadInfo.itemMspB2b) < 0) {
            alert("Please Add the MSP B2B / The MSP B2B price can not be 0")
        } else if (uploadInfo.itemGst === null) {
            alert("Please select the Item GST")
        } else if (uploadInfo.itemCategory === null) {
            alert("Please Add the Item Category")
        } else if (uploadInfo.itemMeasure === null) {
            alert("Please Add the Item Measure")
        } else if (Number(uploadInfo.stockTransferPrice) === 0 || Number(uploadInfo.stockTransferPrice) < 0) {
            alert("Please Add the Item Stock transfer Price / The Item Stock transfer Price price can not be 0")
        } else if (Number(uploadInfo.b2bPrice) === 0 || Number(uploadInfo.b2bPrice) < 0) {
            alert("Please Add the Item B2B Price / The Item B2B Price can not be 0")
        } else if (Number(uploadInfo.homeDeliveryB2c) === 0 || Number(uploadInfo.homeDeliveryB2c) < 0) {
            alert("Please Add the Item Home Delivery B2C Price / The Item Home Delivery B2C price can not be 0")
        } else if (Number(uploadInfo.institutionalB2c) === 0 || Number(uploadInfo.institutionalB2c) < 0) {
            alert("Please Add the Item institutional B2C Price / The Item institutional B2C price can not be 0")
        } else if (Number(uploadInfo.walkInB2c) === 0 || Number(uploadInfo.walkInB2c) < 0) {
            alert("Please Add the Item walkIn B2C Price / The Item walkIn B2C price can not be 0")
        } else if (productIdArray.includes(`${uploadInfo.itemId}`)) {
            alert("ITEM ID already taken for a different product. Either refresh the page to generate a new ID or check the id")
        } else {
            await firestore.collection("warehouse-products").add(uploadInfo)
                .then(async () => await props.updateWarehouseProductsInner())
                .then(async () => await setFinishedAdding(true))
                .then(async () => await onLoadRegenerateCode())
                .then(async () => await props.onHide())
        }
    }

    // Handle Add Category
    const handleCategoryAdd = async () => {
        if (categoryName.length > 0) {
            const catRef = await firestore.collection("stores").doc("coorg-basket").collection("category-meta-data")
            await catRef.add({ category: categoryName })
                .then(async () => await props.updateCategoryData())
                .then(async () => await handleCloseCat())
        } else {
            await alert("Please Input The Category Name To Add")
        }
    }

    // Handle Scan And Add New Product
    const handleScan = async (data) => {
        await setScanData(null);
        await setScanData(data);
        await setScanItem(false);
    }

    const handleError = async (err) => {
        await alert("Error Occured When Scanning", err)
    }

    return (
        <Modal  {...props} size="xl" centered >
            <Modal.Header closeButton>
                <Modal.Title>Add a New Product</Modal.Title>
                {scanItem === true ?
                    <BarcodeReader
                        onError={handleError}
                        onScan={handleScan}
                    // onKeyDetect={handleScan}
                    /> : null}
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    {scanItem === false ?
                        <Button variant={"danger"} onClick={() => setScanItem(true)} >Scan Barcode</Button>
                        : <span style={{ marginBottom: "20px", color: "red" }}><Spinner animation="grow" variant="secondary" size="sm" /> Scan Barcode Now...
                            <span style={{ color: "orange", cursor: "pointer" }} onClick={() => setScanItem(false)}>  &#10060;</span>
                        </span>
                    }
                </Form.Group>
                <div style={{ paddingBottom: "10px", borderBottom: "1px solid grey" }}>
                    <h5>Product Id: {scanData !== null ? `${scanData.toString()}` : `CAT-${productCode}`}</h5>
                </div>
                <Form.Group style={{ paddingTop: "10px" }}>
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control type="text" placeholder="Product Name" onChange={(event) => uploadInfo.name = event.target.value} />
                </Form.Group>
                <div style={{ display: 'flex' }} className='add-product-form-block-1'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Purchase Price</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="Purchase Price" onChange={(event) => uploadInfo.purchasePrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MRP</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MRP" onChange={(event) => uploadInfo.itemMrp = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MSP</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MSP" onChange={(event) => uploadInfo.itemMsp = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>MSP B2B</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >₹</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="MSP B2B" onChange={(event) => uploadInfo.itemMspB2b = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Available Quantity</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type='number' placeholder="Qty." onChange={(event) => uploadInfo.availableQuantity = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item Measure</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '250px' }} as="select" onChange={(event) => uploadInfo.itemMeasure = event.target.value}>
                                <option selected>{null}</option>
                                <option>Kilograms</option>
                                <option>Liters</option>
                                <option>Numbers</option>
                                <option>Packet</option>
                            </FormControl>
                        </InputGroup>
                    </div>
                </div>
                <div style={{ display: 'flex' }} className='add-product-form-block-2'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item GST</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text >%</InputGroup.Text>
                            </InputGroup.Prepend>
                            {/* <FormControl style={{ width: '250px' }} type='number' placeholder="Item GST" onChange={(event) => uploadInfo.itemGst = event.target.value} /> */}
                            <FormControl style={{ width: '250px' }} as="select" onChange={(event) => uploadInfo.itemGst = event.target.value}>
                                <option selected></option>
                                <option>0</option>
                                <option>5</option>
                                <option>12</option>
                                <option>18</option>
                                <option>28</option>
                            </FormControl>
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item HSN</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text ></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '250px' }} type='text' placeholder="Item HSN" onChange={(event) => uploadInfo.itemHsn = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Item Category</Form.Label>
                        <InputGroup style={{ display: "flex" }} className="mb-3">

                            <FormControl style={{ width: '250px' }} as="select" onChange={(event) => uploadInfo.itemCategory = event.target.value}>
                                <option selected>{null}</option>
                                {props.categoryMeta.length > 0 ?
                                    <>
                                        {
                                            props.categoryMeta.map((item, i) =>
                                                <option>{item.category.toLowerCase()}</option>
                                            )
                                        }
                                    </> : <option>Loading...</option>}
                            </FormControl>
                            <Button variant="dark" style={{ width: "150px", fontSize: "0.7rem", marginLeft: "10px" }} onClick={() => handleShowCat()}>Add New Category</Button>
                        </InputGroup>
                    </div>
                </div>
                {/* Different Prices */}
                <div style={{ display: 'flex' }} className='add-product-form-block-2'>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Stock Transfer Price</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Stock Transfer Price" onChange={(event) => uploadInfo.stockTransferPrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>Price For B2B</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Price For B2B" onChange={(event) => uploadInfo.b2bPrice = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Home Delivery</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Home Delivery" onChange={(event) => uploadInfo.homeDeliveryB2c = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Institutional</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Institutional" onChange={(event) => uploadInfo.institutionalB2c = event.target.value} />
                        </InputGroup>
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <Form.Label>B2C Price - Walk In</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl style={{ width: '190px' }} type='number' placeholder="Walk In" onChange={(event) => uploadInfo.walkInB2c = event.target.value} />
                        </InputGroup>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                {finishedAdding === true ?
                    <Button variant='info' onClick={() => handleSubmitProduct()}>Add Product To Inventory</Button>
                    :
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </Button>
                }
            </Modal.Footer>

            {/* Add Categories Modal */}
            <Modal show={showCat} onHide={handleCloseCat} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl style={{ width: '250px' }} type='text' placeholder="category name" onChange={(event) => setCategoryName(event.target.value.toLowerCase())} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleCategoryAdd()}>
                        Add New Category
                    </Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    )
}

export default AddProductModal;