import React, { useState } from 'react';
import './indent-report.styles.scss';
import { Form, Spinner, Table, Button } from 'react-bootstrap';
import { firestore } from '../../firebase/firebase.utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const IndentReport = ({ indentLogs }) => {
    return (
        <div className="indent-report-main-container">
            <h3 style={{ borderBottom: "1px solid grey", paddingBottom: "20px" }}>INDENT REPORT / ORDERS</h3>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="indent-report-to-xls"
                filename="indent-report"
                sheet="tablexls"
                buttonText="Export to XLS" />
            <Table striped bordered hover id="indent-report-to-xls" size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Indent Code</th>
                        <th>Placed On</th>
                        <th>Placed By</th>
                        <th>Placed For</th>
                        <th>Other Products</th>
                        <th>Special Inst.</th>
                        <th>Current Status</th>
                        <th>Change Status</th>
                    </tr>
                </thead>
                {indentLogs.length > 0 ?
                    <tbody style={{ fontSize: "0.9rem" }}>
                        {indentLogs.map((item, i) =>
                            <tr style={item.currentStatus === "indent placed" ? { backgroundColor: "#fff5e6" } : { backgroundColor: "#e6ffe6" }}>
                                <td>{i + 1}</td>
                                <td>{item.indentId}</td>
                                <td>{`${item.timeOfIndent.toDate().toDateString().split(' ').slice(1).join(' ')}`}</td>
                                <td style={{ fontWeight: 'bold' }}>{item.fromStore}</td>
                                {item.productsRequired !== "NA" ?
                                    <td>{item.productsRequired.map((product, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{product.name}</td>
                                            <td>{product.selectedQuantity} {product.itemMeasure}</td>
                                        </tr>
                                    )}</td> : <td>Oral Indent</td>}
                                {item.otherProducts !== "NA" ?
                                    <td style={{ maxWidth: "200px" }}>
                                        {item.otherProducts.map((prod, i) =>
                                            <>
                                                {prod.otherProdName ?
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{prod.otherProdName}</td>
                                                        <td>{prod.otherProdQuantity} {prod.otherProdMeasure}</td>
                                                    </tr> : <tr><td>{prod}</td></tr>}
                                            </>
                                        )}
                                    </td> : <td>Oral Indent</td>}
                                <td>{item.specialInstructions}</td>
                                <td>{item.currentStatus}</td>
                                <td>
                                    {item.currentStatus === 'indent placed' ?
                                        <Button variant="info" style={{ fontSize: "0.8rem" }}
                                            onClick={async () =>
                                                await firestore.collection("indent-requests").where('indentId', '==', item.indentId).limit(1).get().then(async (query) => {
                                                    const indent = await query.docs[0];
                                                    await indent.ref.update({ currentStatus: "indent fulfilled" }).then(async () => await window.location.reload(false));
                                                })
                                            }
                                        >&#10160; Mark As Fulfilled</Button>
                                        : <p style={{ color: "green", fontWeight: 'bold' }}>&#9989; Indent Fulfilled</p>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                    :
                    <td colSpan="8"><Spinner variant="info" size="sm" animation="border" /></td>
                }
            </Table>
        </div>
    )
}

export default IndentReport;