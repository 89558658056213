import React, { useState } from 'react';
import './expense-login.styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { firestore } from '../../../firebase/firebase.utils';

const ExpenseLogin = ({ setExpenseLoggedIn }) => {
    const [emailId, setEmailId] = useState('');
    const [password1, setPassword1] = useState('');

    // HANDLE LOGIN
    const handleLogin = async () => {
        const docRefUser = await firestore.collection("stores").doc("warehouse-catkodagu-profile");
        await docRefUser.get()
            .then((doc) => {
                if (emailId === doc.data().expenseUN && password1 === doc.data().expensePW) {
                    localStorage.setItem("expenseLoggedIn", true);
                    return setExpenseLoggedIn(true);
                } else {
                    alert("Wrong Credentials")
                }
            })
    }

    return (
        <div className='ckw-expense-login-main-container'>
            <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width='200px' alt='ckw-expense-logo' />
            <Form className='admin-login-form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="email" placeholder="Enter Username" onChange={(event) => setEmailId(event.target.value)} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter Password" onChange={(event) => setPassword1(event.target.value)} />
                </Form.Group>
                <Button variant={'dark'} onClick={() => handleLogin()}>Login</Button><br />
                {/* <a className='login-form-forgot-password' onClick={() => handleReset()}>Forgot Password?</a> */}
            </Form>
            <a href='/' style={{ marginTop: "30px", fontSize: "0.7rem" }}><Button variant="success" style={{ fontSize: "0.7rem", width: "250px" }}>Go To Warehouse Portal</Button></a>
            <a href='/expense/admin' style={{ marginTop: "30px", fontSize: "0.7rem" }}><Button variant="info" style={{ fontSize: "0.7rem", width: "250px" }}>Expense Admin</Button></a>
        </div>
    )
}

export default ExpenseLogin;
