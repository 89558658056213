import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';

// Page Imports
import MainPage from './pages/main-page.component';
import ExpenseMainPage from './pages/expense-page.component';
import ExpenseAdmin from './components/expense-logger-app/expense-admin/expense-admin.component';
import CollectionLogger from './components/expense-logger-app/collection-logger/collection-logger.component';
import CollectionsAdmin from './components/expense-logger-app/collections-admin/collections-admin.component';

// Bng Page Imports
import MainPageBng from './warehouse_bangalore/pages/main-page.component';


function App() {
  return (
    <div className="App">
      <Route exact path='/' component={MainPage} />
      <Route exact path='/bng' component={MainPageBng} />
      <Route exact path='/expense' component={ExpenseMainPage} />
      <Route exact path='/expense/admin' component={ExpenseAdmin} />
      <Route exact path='/collections' component={CollectionLogger} />
      <Route exact path='/collections/admin' component={CollectionsAdmin} />
    </div>
  );
}

export default App;
